import React from 'react';
import { useState, useEffect } from 'react';
import { Flex, Segment, List, Divider, Header, Loader, Box, Text, Checkbox } from '@fluentui/react-northstar';
import '../Dashboard/dashboard.scss';
import UserConfig from './userconfig';
import BusinessHoursConfig from './businesshoursconfig';
import AccountSummary from './summary';
import CallQueueConfig from './callqueueconfig';
import APITokenConfig from './apitokenconfig';
import GroupsConfig from './groupsconfig';
import DashboardSchedulesConfig from './dashboardschedulesconfig';
import ConfigurationApiService from '../../../services/ConfigurationApiService';
import AuthHelper from '../../../services/auth-helper';
import '../../Auth/auth.scss';
import Impersonation from "./Impersonation";
import { SignUpConsumer } from '../../Shared/Context/SignUpContext';
import { useHistory } from "react-router-dom";


import {
  ContactGroupIcon,
  SpeakerPersonIcon,
  MeetingTimeIcon,
  SettingsIcon,
  ContactGroupCallIcon,
  ShiftActivityIcon,
  LockIcon,
  EmailIcon,
  NotesIcon,
  ExclamationTriangleIcon,
  OpenOutsideIcon
} from '@fluentui/react-icons-northstar'
import WindowContext from '../../Shared/Context/Context';



const ConfigCategories = (props) => {
  const items = [
    {
      key: 'users',
      header: 'People',
      media: <SpeakerPersonIcon />,
      name: 'People'
    },
    {
      key: 'groups',
      header: 'Groups',
      media: <ContactGroupIcon />,
      name: 'Group'
    },
    {
      key: 'callQueue',
      header: 'Call Queue',
      media: <ContactGroupCallIcon />,
      name: 'callQueue'
    },
    {
      key: 'businesshours',
      header: 'Working Hour Profiles',
      media: <MeetingTimeIcon />,
      name: 'Working Hours'
    },
    {
      key: 'accountsummary',
      header: 'Account',
      media: <SettingsIcon />,
      name: 'Summary'
    },
    {
      key: 'apiToken',
      header: 'API Token',
      media: <NotesIcon />,
      name: 'API Token'
    },
    {
      key: 'dashboardSchedules',
      header: 'Dashboard Schedules',
      media: <EmailIcon />,
      name: 'Dashboard Schedules'
    }
  ]

  const onSelectChange = (index, newProps) => {
    const category = newProps.items[newProps.selectedIndex].key
    const header = newProps.items[newProps.selectedIndex].header
    const name = newProps.items[newProps.selectedIndex].name
    props.handleCategoryChange(index, category, name, header);
  }

  return (
    <WindowContext>{
      (context) => (
        <>
          {(props.isAppOwner || props.isTeamOwner) &&
            <>
              <Divider content={<Text content="Edit People/Group" weight="bold" />} />
              <List
                className="categorylist"
                selectable
                selectedIndex={props.selectedCategory}
                onSelectedIndexChange={(e, newProps) => {
                  onSelectChange(newProps.selectedIndex, newProps)
                }}
                      items={
                          context.loadExternalTeamsCallCards === true ? items.slice(0, 3) : items.slice(0, 2)
                }
                style={{ width: '100%' }}
              />
            </>
          }
          <div className={context.mediaCategory.sm ? "row" : ""}>
            {props.isAppOwner &&
              <>
                <div className={context.mediaCategory.sm ? "col" : ""} >
                  <Divider content={<Text content="Working Hours" weight="bold" />} />
                  <List
                    className="categorylist"
                    selectable
                    selectedIndex={props.selectedCategory - 3}
                    onSelectedIndexChange={(e, newProps) => {
                      onSelectChange(newProps.selectedIndex + 3, newProps)
                    }}
                    items={items.slice(3, 4)}
                    style={{ width: '100%' }}
                  />
                </div>
                <div className={context.mediaCategory.sm ? "col" : ""}>
                  <Divider content={<Text content="Summary" weight="bold" />} />
                  <List
                    className="categorylist"
                    selectable
                    selectedIndex={props.selectedCategory - 4}
                    onSelectedIndexChange={(e, newProps) => {
                      onSelectChange(newProps.selectedIndex + 4, newProps)
                    }}
                    items={items.slice(4, 5)}
                    style={{ width: '100%' }}
                  />
                </div>
              </>
            }
          </div>
          <div className={context.mediaCategory.sm ? "row" : ""}>
            {(props.isAppOwner) &&

              <div className={context.mediaCategory.sm ? "col" : ""} >
                <Divider content={<Text content="API Token" weight="bold" />} />
                <List
                  className="categorylist"
                  selectable
                  selectedIndex={props.selectedCategory - 5}
                  onSelectedIndexChange={(e, newProps) => {
                    onSelectChange(newProps.selectedIndex + 5, newProps)
                  }}
                  items={items.slice(5, 6)}
                  style={{ width: '100%' }}
                />
              </div>

            }
            <div className={context.mediaCategory.sm ? "col" : ""} >
              <Divider content={<Text content="Schedules" weight="bold" />} />
              <List
                className="categorylist"
                selectable
                selectedIndex={props.selectedCategory - 6}
                onSelectedIndexChange={(e, newProps) => {
                  onSelectChange(newProps.selectedIndex + 6, newProps)
                }}
                items={items.slice(6, 7)}
                style={{ width: '100%' }}
              />
            </div>
          </div>
        </>
      )
    }
    </WindowContext>
  )
}


const Configuration = () => {
  const [loading, setLoading] = useState(true);
  const [isAppOwner, setIsAppOwner] = useState(false);
  const [isTeamOwner, setIsTeamOwner] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(0);
  const [category, setCategory] = useState("users");
  const [name, setName] = useState("User");
  const [header, setHeader] = useState("Users");
  const [tenantDetails, setTenantDetails] = useState(null);
  const [loggedInUserEmail, setLoggedInUserEmail] = useState(null);
  const [loggedInUserGUID, setLoggedInUserGUID] = useState(null);

  const [isDemo, setIsDemo] = useState(false);
  const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));

  const history = useHistory();


  useEffect(() => {

    InitialLoad();

  }, []);

    function InitialLoad() {
    if (isDemoMode === "true") {
      setIsDemo(true);
        Impersonation.Impersonate(LoadConfiguration);
    } else {
      setIsDemo(false);
      LoadConfiguration();
    }
  }

  function LoadConfiguration() {
    setLoading(true)
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetLoggedInUserDetail(token)
        .then((response) => {
          if (response != undefined && response != "") {
            setIsAppOwner((response.administrativeAccess === 1 || response.administrativeAccessInherited === 1));
            setIsTeamOwner((response.administrativeAccess === 2 || response.administrativeAccessInherited === 2));
            setLoggedInUserEmail(response.email);
            setLoggedInUserGUID(response.userGUID);
            if (response.administrativeAccess !== 1 && response.administrativeAccess !== 2 && response.administrativeAccessInherited !== 1 && response.administrativeAccessInherited !== 2) {
              setSelectedCategory(6);
              setCategory('dashboardSchedules');
              setName('Dashboard Schedules');
              setHeader('Dashboard Schedules');
            }
            loadTenantData()
          }
          else {
            setLoading(false)
          }
        });
    })
  }

  const loadTenantData = () => {
    AuthHelper.getAccessToken(function (token) {
      ConfigurationApiService.GetTenantDetails(token)
        .then((response) => {
          if (response != undefined && response != "") {
            setTenantDetails(response)
          }
          setLoading(false)
        });
    })
  }

    const handleTenantUpdate = () => {
        setLoading(true);
    loadTenantData();
  }

  const handleCategoryChange = (_categoryIndex, _category, _name, _header) => {
    setSelectedCategory(_categoryIndex);
    setCategory(_category);
    setName(_name);
    setHeader(_header);
    if (global.localStorage) {
      global.localStorage.setItem(
        'contentUrl_configuration',
        'configuration/' + _categoryIndex
      );
    }
  }

  const openInNewTab = (url) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }
  const handleDemoClick = () => {
    setIsDemo(false)
    console.log(isDemo)
    localStorage.removeItem("contentUrl_dashboards");
    localStorage.removeItem("contentUrl_configuration");
    localStorage.removeItem("demoGlobal");
    localStorage.removeItem("demo");
    localStorage.removeItem("impersonationMode");
    window.location.reload(false);
  };

    return (
        (loading) ? <Loader label="Loading..." /> :
            <SignUpConsumer>
                {
                    (tenantStatus) => (
                        <Box>

                            {isDemo && <div class="row rounded-lg cst-shadow signup-banner-config align-items-center alert-primary mx-auto"
                                style={{ overflow: "hidden", lineHeight: "1.25", minWidth: tenantStatus === 100 ? "320px" : "292px" }}>
                                <div class="col">
                                    <div class="row">
                                        {tenantStatus === 100 && <div class="col-auto pl-2" style={{ lineHeight: "1.25" }}>
                                            <ExclamationTriangleIcon />
                                        </div>}
                                        <div class="col pl-0">
                                            {tenantStatus === 100 ? <div class="row">
                                                <div class="col pl-2 pr-0 text-nowrap">
                                                    {/* <p class="m-0"
                            style={{ fontWeight: "300", color: "darkslategray" }}>
                            Analytics</p>
                          <p class="m-0"
                            style={{ fontWeight: "500" }}>365,&nbsp;
                          </p> */}
                                                    Exploring app with sample data,
                                                </div>
                                                <div class="col-auto pl-2 ">
                                                    Sign up <b>NOW</b> !
                                                </div>
                                            </div> : <div className="col-auto pr-0"><b>Exploring app</b> with sample data.</div>}
                                        </div>
                                    </div>
                                </div>
                                {tenantStatus === 100 ? <div class="col-auto h-100 p-1">
                                    < button type="button" style={{ lineHeight: "1" }}
                                        onClick={() => openInNewTab(global.localStorage.getItem("signUpUrl"))}
                                        class="btn h-100 btn-primary"><OpenOutsideIcon className='fUi-1_25' /> Sign up
                                    </button>
                                </div> : <div class="col-auto h-100 p-1">
                                    <Checkbox className="demo-checkbox"
                                        toggle
                                        checked
                                        onClick={() => {
                                            handleDemoClick();
                                        }}
                                    />
                                </div>}

                            </div>}
                            {/* {isDemo && <div className="col text-primary text-center">Exploring the app : <b>Edits not available.</b></div>} */}
                            {(true) ?
                                <Flex className="addcardcontainer" gap="gap.small" padding="padding.medium" style={{ minHeight: '100vh' }}>
                                    <Flex.Item className="leftSideBar" size="size.quarter">
                                        <Segment>
                                            <ConfigCategories isAppOwner={isAppOwner} isTeamOwner={isTeamOwner} selectedCategory={selectedCategory} handleCategoryChange={handleCategoryChange} />
                                        </Segment>
                                    </Flex.Item>

                                    <Flex.Item className="rightSideBar" size="size.half" grow>
                                        <Segment>
                                            {

                                                (selectedCategory === 0 && (isAppOwner || isTeamOwner)) &&
                                                <UserConfig
                                                    isLoggedInUserAdmin={isAppOwner}
                                                    isLoggedInUserTeamOwner={isTeamOwner}
                                                    tenantDetails={tenantDetails}
                                                    loggedInUserEmail={loggedInUserEmail}
                                                    loggedInUserGUID={loggedInUserGUID}
                                                />
                                            }
                                            {
                                                (selectedCategory === 1 && (isAppOwner || isTeamOwner)) &&
                                                <GroupsConfig
                                                    isLoggedInUserAdmin={isAppOwner}
                                                    isLoggedInUserTeamOwner={isTeamOwner}
                                                    tenantDetails={tenantDetails}
                                                    loggedInUserEmail={loggedInUserEmail}
                                                />
                                            }
                                            {
                                                (selectedCategory === 2 && (isAppOwner || isTeamOwner)) &&
                                                <CallQueueConfig
                                                    isLoggedInUserAdmin={isAppOwner}
                                                    isLoggedInUserTeamOwner={isTeamOwner}
                                                    tenantDetails={tenantDetails}
                                                    loggedInUserEmail={loggedInUserEmail}
                                                />
                                            }
                                            {
                                                (selectedCategory === 3 && isAppOwner) &&
                                                <BusinessHoursConfig
                                                    tenantDetails={tenantDetails}
                                                    loggedInUserEmail={loggedInUserEmail}
                                                />
                                            }
                                            {
                                                (selectedCategory === 4 && isAppOwner) &&
                                                <AccountSummary
                                                    handleTenantUpdate={handleTenantUpdate}
                                                    tenantDetails={tenantDetails}
                                                    loggedInUserEmail={loggedInUserEmail}
                                                />
                                            }
                                            {
                                                selectedCategory === 5 &&
                                                <APITokenConfig
                                                    isLoggedInUserAdmin={isAppOwner}
                                                    isLoggedInUserTeamOwner={isTeamOwner}
                                                    tenantDetails={tenantDetails}
                                                    loggedInUserEmail={loggedInUserEmail}
                                                />
                                            }
                                            {
                                                selectedCategory === 6 &&
                                                <DashboardSchedulesConfig
                                                    isLoggedInUserAdmin={isAppOwner}
                                                    isLoggedInUserTeamOwner={isTeamOwner}
                                                    tenantDetails={tenantDetails}
                                                    loggedInUserEmail={loggedInUserEmail}
                                                />
                                            }
                                        </Segment>
                                    </Flex.Item>
                                </Flex>
                                :
                                <Flex fill className="default-segment-container" hAlign="center" vAlign="center">
                                    <Segment className="auth-form-segment default-segment">
                                        <Flex gap="gap.small" column hAlign="center" vAlign="center">
                                            <Text weight="semibold" size="large" content="Access Denied" />
                                            <Text content="Access to settings is restricted to App Owners or Team Owners." />
                                        </Flex>
                                    </Segment>
                                </Flex>
                            }
                        </Box>
                    )
                }
            </SignUpConsumer >
  )
}

export default Configuration;
