import {useEffect, useState} from 'react';
import { useHistory } from 'react-router-dom';
import WindowContext from './Context';
import useWindowSize from '../CustomHooks/useWindowSize';
import {useTeams} from '../Themes/useTeams';
import * as constants from "../../../constants";

const getRouteFromLocalStorage = (key) => {
    let url = '';
    if (global.localStorage) {
        try {
            url = global.localStorage.getItem(key) || '';
        } catch (e) {
            /*Ignore*/
        }
    }

    if (url === '') {
        if (global.localStorage) {
            global.localStorage.setItem(
                key,
                '/' + key.split('_')[1]
            );
        }
        url = '/' + key.split('_')[1];
    }
    return url;
}

const WindowProvider = ({ children }) => {
    const [{ windowSize, mediaCategory }, { }] = useWindowSize();
    const [latestDashboardJourneyState, setlatestDashboardJourneyState] = useState(0);
    const [latestDemoDashboardJourneyState, setlatestDemoDashboardJourneyState] = useState(0);
    const [loadExternalTeamsCallCards, setLoadExternalTeamsCallCards] = useState(false);

    const options = {
        initialTheme: "default",
        contentUrl: getRouteFromLocalStorage('contentUrl_' + (window.location.pathname.includes('configuration') ? 'configuration' : 'dashboards'))
    };
    const [{ inTeams, fullScreen, theme, themeString, hostClientType }, { setTheme }] = useTeams({ options });
    let _history = useHistory();
    useEffect(() => {

        var sdDoesntContains = window.location.href.toLowerCase().indexOf("/sd/") == -1;
        var scDoesntContains = window.location.href.toLowerCase().indexOf("/sc/") == -1;

        if (!constants.isLocal &&
            sdDoesntContains && 
            scDoesntContains)
        {
            _history.replace(options.contentUrl);
        }
    }, []);

    return (
        <WindowContext.Provider
            value={{
                windowSize: windowSize,
                mediaCategory: mediaCategory,
                teams:{
                    inTeams: inTeams,
                    fullScreen: fullScreen,
                    theme: theme,
                    themeString: themeString,
                    hostClientType: hostClientType,
                    setTheme: setTheme
                },
                journeyState: {
                    latestDashboardJourneyState: latestDashboardJourneyState,
                    setlatestDashboardJourneyState: setlatestDashboardJourneyState,
                    latestDemoDashboardJourneyState: latestDemoDashboardJourneyState,
                    setlatestDemoDashboardJourneyState: setlatestDemoDashboardJourneyState,
                },
                loadExternalTeamsCallCards: loadExternalTeamsCallCards,
                setLoadExternalTeamsCallCards: setLoadExternalTeamsCallCards,
                constants: constants
            }}
        >
            {children}
        </WindowContext.Provider>
    );
}

export default WindowProvider;