import React, { useState, useEffect, Fragment } from "react";
// import { TimePicker } from "@progress/kendo-react-dateinputs";
import {
    Grid,
    GridColumn as Column,
    GridNoRecords,
} from "@progress/kendo-react-grid";
import {
    AcceptIcon,
    ExclamationTriangleIcon,
} from "@fluentui/react-icons-northstar";

import { getter } from "@progress/kendo-react-common";

import {
    Form,
    Loader,
    Flex,
    Dialog as FluentDialog,
    Button,
    Text,
    Checkbox,
    Input,
    FormLabel,
    FormDropdown,
    FormField,
    FormCheckbox,
    FormButton
} from "@fluentui/react-northstar";

import {
    CellWithTypeIcon,
    CellWithCategoryIcon,
} from "../../../pages/CustomCells/CustomCells";
import { orderBy } from "@progress/kendo-data-query";
import FiltersApiService from "../../../../services/FiltersApiService";
import DashboardApiService from "../../../../services/DashboardApiService";
import AuthHelper from "../../../../services/auth-helper";
import {
    FilterTypes,
    cardFilters,
} from "../CardFiltersData/CardFiltersData";
import { Dialog } from "@progress/kendo-react-dialogs";
import { WidgetPreview } from "../../../cards/Card/Card";
import CenteredHeader from "../../../Shared/Header/Header";
import "./MultiCardSettings.scss";
import WindowContext from "../../../Shared/Context/Context";
import TeamsTimePicker from "../../../Shared/uicomponents/TeamsTimePicker";
import NativeTimePicker from "../../../Shared/uicomponents/NativeTimePicker";
import TeamsDatePicker from "../../../Shared/uicomponents/TeamsDatePicker";
import NativeDatePicker from "../../../Shared/uicomponents/NativeDatePicker";
import * as moment from "moment";
const DATA_ITEM_KEY = "cardID";
const SELECTED_FIELD = "checked";
const idGetter = getter(DATA_ITEM_KEY);

const CellWithViewIcon = (props) => {
    const data = props.dataItem;
    return (
        <Button
            // title="Preview"
            onClick={() => props.handleExpandChange(data)}
            className="widget_category_container"
            text
            iconOnly
            content={
                <>
                    {data.categoryTypeID === 1 && (
                        <i title="Click to view" className={"analytics-summary"} />
                    )}
                    {data.categoryTypeID === 2 && (
                        <i title="Click to view" className={"analytics-activity2"} />
                    )}
                    {data.categoryTypeID === 3 && (
                        <i title="Click to view" className={"analytics-trends"} />
                    )}
                    {data.categoryTypeID === 4 && (
                        <i title="Click to view" className={"analytics-comparison2"} />
                    )}
                    {data.categoryTypeID === 5 && (
                        <i title="Click to view" className={"analytics-insights"} />
                    )}
                    {data.categoryTypeID === 6 && (
                        <i title="Click to view" className={"analytics-list"} />
                    )}
                </>
            }
        />
    );
};

const DetailComponent = (props) => {
    const dataItem = props.dataItem;
    return (
        <section style={{ paddingRight: "32px" }}>
            <WidgetPreview
                widgetID={dataItem.cardWidgetID}
                viewTypeID={dataItem.cardViewTypeID}
            />
        </section>
    );
};

const MultiCardSettingsEdit = (props) => {
    const [activeIndex, setActiveIndex] = useState(0);
    const [dashboardId, setDashboardId] = useState(props.dashboardId);
    const [loaded, setLoaded] = useState(false);
    const [filterData, setFilterData] = useState(props.userFiltersData);
    const [submitButtonState, setSubmitButtonState] = useState(0);

    const [date, setDate] = useState(1);
    const [time, setTime] = useState(0);
    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [startTime, setStartTime] = useState("");
    const [endTime, setEndTime] = useState("");
    const [teams, setTeams] = useState([]);
    const [groups, setGroups] = useState([]);
    const [users, setUsers] = useState([]);
    const [callQueues, setCallQueues] = useState([]);
    const [agents, setAgents] = useState([]);

    const [dateItems, setDateItems] = useState([]);
    const [timeItems, setTimeItems] = useState([]);
    const [filterTypes, setFilterTypes] = useState([]);
    const [filterTypeId, setFilterTypeId] = useState(0);
    const [filterType, setFilterType] = useState(FilterTypes[0]);
    const [isValid, setIsValid] = useState(false);
    const [organization, setOrganization] = useState(false);
    const [teamIsGroup, setTeamIsGroup] = useState(false);
    const [teamIsGroupDisabled, setTeamIsGroupDisabled] = useState(false);
    const [allcards, setAllcards] = useState(props.addcardList);
    const [expandPeople, setExpandPeople] = useState(0);
    const [peopleMasKList, setPeopleMaskList] = useState([]);

    const [widgetNameMap, setWidgetNameMap] = useState(null);
    const [selectedState, setSelectedState] = useState({});
    const [filterProperty, setFilterProperty] = useState("");
    const [filterValue, setFilterValue] = useState(0);
    const [widgets, setWidgets] = useState([]);
    const [isSaveEnabled, setIsSaveEnabled] = useState(false);
    const [openPreview, setOpenPreview] = useState(false);
    const [previewObj, setPreviewObj] = useState(null);
    const [checkedItems, setCheckedItems] = useState(new Map());
    const [open, setOpen] = useState(false);
    const [dropDownOptions, setDropDownOptions] = useState([]);
    const [editPeriod, setEditPeriod] = useState(false);
    const [editTime, setEditTime] = useState(false);
    const [editPeople, setEditPeople] = useState(false);
    const [havePeople, setHavePeople] = useState(false);
    const [teamIsGroupList, setTeamIsGroupList] = useState(null);
    const [teamIsNotGroupList, setTeamIsNotGroupList] = useState(null);
    const [sort, setSort] = useState([]);

    const [isDemo, setIsDemo] = useState(false);
    const [isDemoMode, setIsDemoMode] = useState(global.localStorage.getItem("demoGlobal"));
    const [submitDemoButtonState, setSubmitDemoButtonState] = useState(0);
    const [cardData, setCardData] = useState(props.cardData);
    const [allCallQueues, setallCallQueues] = useState(false);
    const [allGroups, setallGroups] = useState(false);
    const [allUsers, setallUsers] = useState(false);
    const [allTeams, setallTeams] = useState(false);
    const [allAgents, setallAgents] = useState(false);

    const [_fromDateLabel, _setFromDateLabel] = useState();
    const [_toDateLabel, _setToDateLabel] = useState();

    useEffect(() => {
        if (isDemoMode === "true") {
            setIsDemo(true);
        } else {
            setIsDemo(false);
        }
    }, []);

    useEffect(() => {
        //   debugger;
        //setWidgetNameMap(WIDGETSNAMEMAP);
        setFilterData(props.userFiltersData);
        setStates(props.userFiltersData, props.addcardList);
        setPeopleOptions(props.userFiltersData);
    }, []);
    useEffect(() => {
        var list = [];
        let data = { ...props.userFiltersData.teamFilter };
        for (let i = 0; i < data.items.length; i++) {
            if (data.items[i].teamIsGroup === false)
                list.push(data.items[i]);
        }
        const sortedList = list.sort((a, b) => {
            const headerA = a.header.toUpperCase();
            const headerB = b.header.toUpperCase();
            if (headerA < headerB) {
                return -1;
            }
            if (headerA > headerB) {
                return 1;
            }
            return 0;
        });
        setTeamIsNotGroupList(sortedList);
    }, []);
    useEffect(() => {
        if (activeIndex === 1) {

            const WIDGETSWITHFILTERS = allcards.map((obj) => {
                return {
                    ...obj,
                    ...cardFilters[obj.cardFilterID],
                    checked: false,
                };
            });
            // setWidgets(WIDGETSWITHFILTERS);
            setLoaded(true);
        } else {
            setLoaded(false);
        }
    }, [activeIndex]);

    const handleQuarter = (value) => {
        const today = new Date();
        const quarter = Math.floor((today.getMonth() / 3));
        var _fromDateLabel;
        if (value === 9) {
            _fromDateLabel = new Date(today.getFullYear(), quarter * 3, 1);
        }
        else if (value === 10) {
            _fromDateLabel = new Date(today.getFullYear(), quarter * 3 - 3, 1);
        }

        if (value === 9 || value === 10) {
            let _toDateLabel = new Date(_fromDateLabel.getFullYear(), _fromDateLabel.getMonth() + 3, 0);

            _setFromDateLabel(_fromDateLabel.toLocaleString('en-US', {
                year: "numeric",
                month: "long",
                day: "numeric",
            }));
            _setToDateLabel(_toDateLabel.toLocaleString('en-US', {
                year: "numeric",
                month: "long",
                day: "numeric",
            }));
        }
    }

    const selectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        const dataItemKey = idGetter(event.dataItem);

        setSelectedState({ ...selectedState, [dataItemKey]: checked });
        setCheckedItems(checkedItems.set(dataItemKey, checked));

        let checkedArr = [];
        let unCheckedArr = [];

        for (let [key, value] of checkedItems.entries()) {
            if (value === true) {
                checkedArr.push(key);
            } else {
                unCheckedArr.push(key);
            }
        }

        if (checkedArr.length >= 1) {
            setIsSaveEnabled(true);
        } else {
            setIsSaveEnabled(false);
        }
        if (isDemo)
            setIsSaveEnabled(true);
    };

    const headerSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;
        const newSelectedState = {};
        widgets.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
            setCheckedItems(checkedItems.set(idGetter(item), checked));
        });
        setIsSaveEnabled(checked);
        setSelectedState(newSelectedState);

        let checkedArr = [];
        let unCheckedArr = [];

        for (let [key, value] of checkedItems.entries()) {
            if (value === true) {
                checkedArr.push(key);
            } else {
                unCheckedArr.push(key);
            }
        }

        if (checkedArr.length >= 1) {
            setIsSaveEnabled(true);
        } else {
            setIsSaveEnabled(false);
        }
        if (isDemo)
            setIsSaveEnabled(true);
    };

    const handleExpandChangeOnTypeCell = (dataItem) => {
        let newData = widgets.map((item) => {
            if (item.widgetName === dataItem.widgetName) {
                item.expanded = !dataItem.expanded;
            }
            return item;
        });
        setWidgets(newData);
    };

    const checkValid = (_filterType, _selectCount) => {
        if (_filterType === 0) {
            return _selectCount >= 1 ? true : false;
        } else if (_filterType === 1) {
            return _selectCount >= 1 ? true : false;
        } else if (_filterType === 2) {
            return _selectCount >= 1 ? true : false;
        } else if (_filterType === 3) {
            return _selectCount >= 1 ? true : false;
        } else if (_filterType === 4) {
            return _selectCount >= 1 ? true : false;
        }
    };

    const setPeopleOptions = (_userFilterData) => {
        let peopleOptions = _userFilterData.userFilter.items.map((e) => e);
        let directReporteeArr = [];
        peopleOptions.slice(2).forEach((el) => {
            if (el.isdirectreportee === false) {
                directReporteeArr.push(el);
            }
        });

        if (directReporteeArr.length === peopleOptions.slice(2).length) {
            let updateArr = peopleOptions.filter((el) => el.key !== -2);
            setDropDownOptions(updateArr);
        } else {
            setDropDownOptions(peopleOptions);
        }
    };

    //Initial setup
    const setStates = (_filterData, _cardData) => {
        setSubmitButtonState(0);
        setExpandPeople(0);
        // setPeopleMaskList([]);
        setFilterTypes([]);
        setFilterTypeId(0);
        setFilterType(FilterTypes[0]);
        setCardData(_cardData);
        let settingsData = {
            runPeriod: 3,
            timePeriod: 2,
            selectedStartDate: moment().toDate(),
            selectedEndDate: moment().toDate(),
            selectedStartTime: "00:00",
            selectedEndTime: "23:59",
        };
        setDate(settingsData.runPeriod);
        setTime(settingsData.timePeriod);
        setStartTime(
            settingsData.selectedStartTime !== ""
                ? settingsData.selectedStartTime
                : "00:00"
        );
        setEndTime(
            settingsData.selectedEndTime !== ""
                ? settingsData.selectedEndTime
                : "23:59"
        );
        setStartDate(settingsData.selectedStartDate);
        setEndDate(settingsData.selectedEndDate);
        setFilterTypes(FilterTypes);
        setDateItems(_filterData.dateFilter.items);
        setTimeItems(_filterData.timeFilter.items);

        // setLoaded(true);
    };

    const handleClick = () => {
        if (isDemo) {
            setSubmitDemoButtonState(1)
        }
        else {
            setSubmitButtonState(1);
        }
        let widgetIdArr = [];
        let nameArr = [];
        Object.keys(selectedState).forEach((key) => {
            if (selectedState[key] === true) {
                let widgetId = Number(key);
                widgetIdArr.push(widgetId);
                //nameArr.push(widgetNameMap.get(widgetId));
                nameArr.push((allcards.filter(el => el.cardWidgetID === widgetId)).widgetName);
            }
        });

        let settingsData = {

            cardId: -1,
            Name: nameArr.toString(),
            dashboardId: dashboardId,
            widgetIdList: widgetIdArr.toString(),
            runPeriod: date,
            timePeriod: time,
            selectedStartTime: time === 2 ? startTime : "",
            selectedEndTime: time === 2 ? endTime : "",
            selectedStartDate: date === 5 ? (new Date(startDate)).toDateString() : null,
            selectedEndDate: date === 5 ? (new Date(endDate)).toDateString() : null,
            TeamFilter:
                filterTypeId === 1 ? teams.map((d) => d.value).toString() : "",
            teamIsGroup: filterTypeId === 1 ? teamIsGroup : false,
            GroupFilter: filterTypeId === 2 ? groups.map((d) => d.value).toString() : "",
            userFilter: filterTypeId === 0 ? users.map((d) => d.value).toString() : "",
            callQueueFilter: filterTypeId === 3 ? callQueues.map((d) => d.value).toString() : "",
            agentFilter: filterTypeId === 4 ? agents.map((d) => d.value).toString() : "",
            callQualityFilter: "0,1,2",
            sentimentFilter: "0,1,2",
            reactionFilter: "0,1,2,3,4,5,6",
            modalitiesFilter: "0,1,2",
            msgParticipantsFilter: "0,1",
            callParticipantsFilter: "0,1",
            joinedAsFilter: "0,1",
            collaborationTypeFilter: "0,1",
            messagesFilter: false,
            xPosition: 0,
            yPosition: 0,
            timeCheckBox: editTime,
            periodCheckBox: editPeriod,
            peopleCheckBox: editPeople
        };
        if (isDemo) {
            settingsData.userFilter = "";
            settingsData.agentFilter = "";
            settingsData.TeamFilter = "";
            settingsData.GroupFilter = "";
            settingsData.callQueueFilter = "";
        }

        AuthHelper.getAccessToken(function (token) {
            FiltersApiService.UpdateMultiCardWithFilters(settingsData, token)
                .then((response) => {
                    if (response) {
                        if (response === "/dashboards") {
                            window.location.replace("/dashboards");
                        }
                        setSubmitButtonState(2);
                        props.submitHandler(settingsData);
                    } else {
                        setSubmitButtonState(3);
                    }
                })
                .finally(() => {
                    setTimeout(function () {
                        setSubmitButtonState(0);
                    }, 1000);
                });
        });
    };

    const handleFilterTypeChange = (value) => {
        setFilterTypeId(value);
        if (value === 0) {
            setFilterProperty("users");
            setFilterValue(users.some((v) => v.key < 0) ? 2 : users.length === 2 ? 3 : users.length > 1 ? 2 : 1);
            setIsValid(checkValid(0, users.length));
        }
        else if (value === 1) {
            setFilterProperty("teams");
            setFilterValue(teams.length === 2 ? 3 : teams.length > 1 ? 2 : 1);
            setIsValid(checkValid(1, teams.length));
            setTime(2);
            //setStartTime("00:00:00");
            //setEndTime("23:59:59");
        }
        else if (value === 2) {
            setFilterProperty("groups");
            setFilterValue(groups.length === 2 ? 3 : groups.length > 1 ? 2 : 1);
            setIsValid(checkValid(2, groups.length));
        }
        else if (value === 3) {
            setFilterProperty("callQueues");
            setFilterValue(callQueues.length === 2 ? 3 : callQueues.length > 1 ? 2 : 1);
            setIsValid(checkValid(1, callQueues.length));
        }
        else if (value === 4) {
            setFilterProperty("agents");
            setFilterValue(agents.length === 2 ? 3 : agents.length > 1 ? 2 : 1);
            setIsValid(checkValid(1, agents.length));
        }
    };

    const handleAllUsers = (checked) => {
        if (checked) {
            let data = filterData.userFilter.items.filter((e) => !e.disabled && filterData.userFilter.items.findIndex((item) => item.id === e.id) > -1).concat(filterData.userFilter.items.filter((e) => e.disabled));
            setUsers(data);
            handleUserChange(
                data
            );
        } else {
            let data = filterData.callQueueFilter.items.filter((e) => e.disabled);
            handleUserChange(
                data
            );
            setUsers(data);
        }
        setallUsers(checked);
    }

    const handleAllTeams = (checked) => {
        if (checked) {
            //console.log(cardFilters);

            let data = filterData.teamFilter.items.filter((e) => !e.disabled && filterData.teamFilter.items.findIndex((item) => item.id === e.id) > -1).concat(filterData.teamFilter.items.filter((e) => e.disabled));
            setTeams(data);
            handleTeamChange(
                data
            );
        } else {
            let data = filterData.teamFilter.items.filter((e) => e.disabled);
            handleTeamChange(
                data
            );
            setTeams(data);
        }
        setallTeams(checked);
    }

    const handleAllGroups = (checked) => {
        if (checked) {
            let data = filterData.groupFilter.items.filter((e) => !e.disabled && filterData.groupFilter.items.findIndex((item) => item.id === e.id) > -1).concat(filterData.groupFilter.items.filter((e) => e.disabled));
            setGroups(data);
            handleGroupChange(
                data
            );
        } else {
            let data = filterData.callQueueFilter.items.filter((e) => e.disabled);
            handleGroupChange(
                data
            );
            setGroups(data);
        }
        setallGroups(checked);
    }

    const handleAllcallQueues = (checked) => {
        if (checked) {
            let data = filterData.callQueueFilter.items.filter((e) => !e.disabled && filterData.callQueueFilter.items.findIndex((item) => item.id === e.id) > -1).concat(filterData.callQueueFilter.items.filter((e) => e.disabled));
            setCallQueues(data);
            handleCallQueueChange(
                data
            );
        } else {
            let data = filterData.callQueueFilter.items.filter((e) => e.disabled);
            handleCallQueueChange(
                data
            );
            setCallQueues(data);
        }
        setallCallQueues(checked);
    }

    const handleAllAgents = (checked) => {
        if (checked) {
            let data = filterData.agentFilter.items.filter((e) => !e.disabled && filterData.agentFilter.items.findIndex((item) => item.id === e.id) > -1).concat(filterData.agentFilter.items.filter((e) => e.disabled));
            setAgents(data);
            handleAgentChange(
                data
            );
        } else {
            let data = filterData.agentFilter.items.filter((e) => e.disabled);
            handleAgentChange(
                data
            );
            setAgents(data);
        }
        setallAgents(checked);
    }


    const handleTeamChange = (value) => {
        let checkTeamIsGroup = value.filter(el => el.teamIsGroup === false);
        if (checkTeamIsGroup.length === 0) {
            setTeamIsGroupDisabled(false)
        } else {
            setTeamIsGroupDisabled(true)
            setTeamIsGroup(false)
        }
        setTeams(value);
        setFilterProperty("teams");
        setFilterValue(value.length === 2 ? 3 : value.length > 1 ? 2 : 1);
        setIsValid(checkValid(1, value.length));
        setHavePeople(checkValid(1, value.length) == true ? true : false);
    };

    const handleGroupChange = (value) => {
        setGroups(value);
        setFilterProperty("groups");
        setFilterValue(value.length === 2 ? 3 : value.length > 1 ? 2 : 1);
        setIsValid(checkValid(2, value.length));
        setHavePeople(checkValid(2, value.length) == true ? true : false);
    };

    const handleCallQueueChange = (value) => {
        setCallQueues(value);
        setFilterProperty("callQueues");
        setFilterValue(value.length === 2 ? 3 : value.length > 1 ? 2 : 1);
        setIsValid(checkValid(2, value.length));
        setHavePeople(checkValid(2, value.length) == true ? true : false);
    };

    const handleAgentChange = (value) => {
        setAgents(value);
        setFilterProperty("agents");
        setFilterValue(value.length === 2 ? 3 : value.length > 1 ? 2 : 1);
        setIsValid(checkValid(2, value.length));
        setHavePeople(checkValid(2, value.length) == true ? true : false);
    };

    const handleUserChange = (value) => {

        setUsers(value);
        setFilterProperty("users");
        setFilterValue(
            value.some((v) => v.key < 0)
                ? 2
                : value.length === 2
                    ? 3
                    : value.length > 1
                        ? 2
                        : 1
        );
        setIsValid(checkValid(0, value.length));
        setHavePeople(checkValid(0, value.length) == true ? true : false);
    };

    const onExpandCancel = () => {
        setExpandPeople(0);
    };

    const onExpandConfirm = () => {
        let currentSelection = users;
        currentSelection.splice(
            currentSelection.findIndex((cs) => cs.value === expandPeople),
            1
        );
        if (expandPeople === -1) {
            filterData.userFilter.items
                .filter((f) => f.key > 0)
                .forEach(function (obj, v) {
                    if (currentSelection.findIndex((f) => f.value === obj.value) === -1) {
                        currentSelection.push(obj);
                    }
                });
            setPeopleMaskList([-1, -2]);
        } else if (expandPeople === -2) {
            filterData.userFilter.items
                .filter((f) => f.key > 0 && f.isdirectreportee === true)
                .forEach(function (obj, v) {
                    if (currentSelection.findIndex((f) => f.value === obj.value) === -2) {
                        currentSelection.push(obj);
                    }
                });
            setPeopleMaskList([-2]);
        }
        setUsers(currentSelection);
        setExpandPeople(0);
    };

    const ViewCell = (props) => {
        return (
            <td className="text-center">
                <CellWithViewIcon
                    {...props}
                    handleExpandChange={(_prewiewObj) => {
                        handleExpandChangeOnTypeCell(_prewiewObj);
                    }}
                />
            </td>
        );
    };

    const onPrevious = () => {
        // setActiveIndex(activeIndex - 1);
        // setSelectedState({});
        if (isSaveEnabled === true) {
            setOpen(true);
        } else {
            setActiveIndex(activeIndex - 1);
            setSelectedState({});
        }
    };

    const onNext = (time) => {

        const WIDGETSWITHFILTERS = allcards.map((obj) => {
            return {
                ...obj,
                ...cardFilters[obj.cardFilterID],
                checked: false,
            };
        })
        AuthHelper.getAccessToken(function (token) {
            DashboardApiService.GetDashboardCardsWithFilters(props.dashboardId, token).then((response) => {
                let existing_widgets = [];
                if (response != null) {
                    if (response.length > 0) {
                        for (let i = 0; i < response.length; i++) {
                            const current_widget = (time === 2) ? WIDGETSWITHFILTERS.filter((f) => f.cardWidgetID === response[i].cardWidgetID) : WIDGETSWITHFILTERS.filter((f) => f.cardWidgetID === response[i].cardWidgetID/* && response[i].teams.length === 0*/);
                            const returnedTarget = Object.assign(response[i], current_widget[0]);
                            if (time === 2)
                                existing_widgets.push(returnedTarget);
                            else if (response[i].teams.length === 0 || response[i].teams.length === undefined)
                                existing_widgets.push(returnedTarget);
                        }
                        if (users.length > 1 || teams.length > 1) {
                            for (let x = 0; x < existing_widgets.length; x++) {
                                if ((existing_widgets.findIndex((cs) => cs.cardFilterID === 21) || (existing_widgets.findIndex((cs) => cs.cardFilterID === 28))) !== -1) {
                                    existing_widgets.splice(existing_widgets.findIndex((cs) => cs.cardFilterID === 21), 1)
                                    existing_widgets.splice(existing_widgets.findIndex((cs) => cs.cardFilterID === 28), 1)

                                }
                            }
                        }


                        let haveMyPeople = false;
                        let have2CallQueues = false;
                        if (users.length > 0) {
                            for (let i = 0; i < users.length; i++) {
                                if (users[i].header === "My Reporting Network" || users.length > 1)
                                    haveMyPeople = true;
                            }

                        }
                        else if (callQueues.length > 1) {
                            have2CallQueues = true;
                        }

                        if (haveMyPeople) {
                            // if(existing_widgets.findIndex((cs) => cs.cardFilterID === 21) !== -1)
                            // {
                            //   existing_widgets.splice(existing_widgets.findIndex((cs) => cs.cardWidgetID === 0), 1)
                            // }
                        }
                        else if (have2CallQueues) {
                            if (existing_widgets.findIndex((cs) => cs.callQueues === 1) !== -1) {
                                existing_widgets.splice(existing_widgets.findIndex((cs) => cs.callQueues === 1), 1)
                            }
                        }

                        if (filterValue === 3) {
                            setWidgets(existing_widgets.filter((f) => f[filterProperty == "teams" && teamIsGroup ? "groups" : filterProperty] !== 0));
                        } else {
                            setWidgets(existing_widgets.filter((f) => f[filterProperty == "teams" && teamIsGroup ? "groups" : filterProperty] !== 0
                                && f[filterProperty == "teams" && teamIsGroup ? "groups" : filterProperty] !== 3));
                        }
                    }
                }
            });
        });
        setIsSaveEnabled(false);
        setActiveIndex(activeIndex + 1);
    };

    const onConfirm = () => {
        setActiveIndex(activeIndex - 1);
        setOpen(false);
        setSelectedState({});
    };

    const onCancel = () => {
        setOpen(false);
    };

    const onTeamIsGroup = (checked) => {
        setTeamIsGroup(checked);
        if (checked) {
            var list = [];
            let data = { ...props.userFiltersData.teamFilter };
            for (let i = 0; i < data.items.length; i++) {
                if (data.items[i].teamIsGroup === true)
                    list.push(data.items[i]);
            }
            const sortedList = list.sort((a, b) => {
                const headerA = a.header.toUpperCase();
                const headerB = b.header.toUpperCase();
                if (headerA < headerB) {
                    return -1;
                }
                if (headerA > headerB) {
                    return 1;
                }
                return 0;
            });
            setTeamIsGroupList(sortedList);
            //console.log(props.userFiltersData); console.log(sortedList); console.log(data);
        }
    }

    const onDisableCancel = () => {
        if (!editPeople && !editPeriod && !editTime)
            return false;
        else
            return true;
    }
    const sortChange = (event) => {
        if (event.sort.length === 0) {
            setSort(event.sort);
            return;
        }
        var sortOrder = event.sort[0].dir;
        var columnName = event.sort[0].field;
        if (columnName === "widgetName") {
            let sortArray = [{ field: 'widgetName', dir: sortOrder }];
            setSort(sortArray);
        }
        else
            if (columnName === "collaborationTypeID") {
                let sortArray = [{ field: 'collaborationTypeID', dir: sortOrder }];
                setSort(sortArray);
            }
            else
                if (columnName === "name") {
                    let sortArray = [{ field: 'name', dir: sortOrder }];
                    setSort(sortArray);
                }
    };
    return (
        <WindowContext.Consumer>
            {(context) => (
                <Fragment>
                    <Form
                        className="formCardSettings mb-0"
                        style={{ overflow: "hidden", minHeight: "70vh" }}
                    >
                        <div
                            className="multiCard-step-content-holder mb-0"
                            style={{ transform: `translateX(-${activeIndex * 50}%)`, height: "calc(100% - 42px)" }}
                        >
                            <div style={{ width: "100%", padding: "0 1em" }}>
                                <Text content="Please select the filter parameters that you wish to edit." />
                                <div className="dropdownSection">
                                    <FormCheckbox
                                        id="quickEditPeriodCheck"
                                        label="Period"
                                        value={editPeriod}
                                        checked={editPeriod}
                                        onChange={(e, { checked }) => { setEditPeriod(checked) }}
                                    />
                                    <FormDropdown
                                        id="quickEditPeriodInput"
                                        fluid
                                        
                                        disabled={!editPeriod}
                                        // label="Period"
                                        items={dateItems}
                                        defaultValue={
                                            dateItems[
                                            dateItems.map((e) => e.value).indexOf(date) === -1
                                                ? 0
                                                : dateItems.map((e) => e.value).indexOf(date)
                                            ]
                                        }
                                        value={
                                            dateItems[
                                            dateItems.map((e) => e.value).indexOf(date) === -1
                                                ? 0
                                                : dateItems.map((e) => e.value).indexOf(date)
                                            ]
                                        }
                                        onChange={(e, { value }) => {
                                            setDate(value.value);
                                        }}
                                    />
                                    {date === 5 ? (
                                        <> <div></div>
                                            <div className="custom-date-container">
                                                <div>
                                                    <label>Start Date</label>
                                                    {
                                                        context.teams.hostClientType.web ||
                                                            context.teams.hostClientType.desktop ? (
                                                            <TeamsDatePicker
                                                                required={true}
                                                                value={startDate}
                                                                maxDate={endDate}
                                                                onChange={(value) => {
                                                                    setStartDate(value);
                                                                }}
                                                            />
                                                        ) : (
                                                            <NativeDatePicker
                                                                id="startDate"
                                                                value={startDate}
                                                                maxDate={endDate}
                                                                onChange={(value) => {
                                                                    setStartDate(value);
                                                                }}
                                                                required={true}
                                                            />
                                                        )
                                                        // <FormField
                                                        //   className="timePicker"
                                                        //   control = {{ as: Input, value: startTime, type: 'time',
                                                        //     onChange: (e)=>{setStartTime(e.target.value)}
                                                        //   }}
                                                        // />
                                                    }
                                                </div>
                                                <div>
                                                    <label>End Date</label>
                                                    {
                                                        context.teams.hostClientType.web ||
                                                            context.teams.hostClientType.desktop ? (
                                                            <TeamsDatePicker
                                                                required={true}
                                                                minDate={startDate}
                                                                value={endDate}
                                                                onChange={(value) => {
                                                                    setEndDate(value);
                                                                }}
                                                            />
                                                        ) : (
                                                            <NativeDatePicker
                                                                id="endDate"
                                                                minDate={startDate}
                                                                value={endDate}
                                                                onChange={(value) => {
                                                                    setEndDate(value);
                                                                }}
                                                                required={true}
                                                            />
                                                        )
                                                        // <FormField
                                                        //   className="timePicker"
                                                        //   control = {{ as: Input, value: endTime, type: 'time',
                                                        //     onChange: (e)=>{setEndTime(e.target.value)}
                                                        //   }}
                                                        // />
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>
                                {(date === 9 || date === 10) && <FormLabel className="form-text text-right">
                                    {handleQuarter(date)}
                                    <span className="text-secondary">Selected Quarter is</span> {_fromDateLabel} <span className="text-secondary">to</span> {_toDateLabel}</FormLabel>}
                                {<div className="dropdownSection my-3">
                                    <FormCheckbox
                                        id="quickEditTimeCheck"
                                        label="Time"
                                        value={editTime}
                                        checked={editTime}
                                        onChange={(e, { checked }) => { setEditTime(checked) }}
                                    />
                                    <FormDropdown
                                        id="quickEditTimeInput"
                                        fluid
                                        
                                        // label="Time"
                                        disabled={!editTime || filterTypeId === 1}
                                        items={timeItems}
                                        defaultValue={
                                            timeItems[
                                            timeItems.map((e) => e.value).indexOf(time) === -1
                                                ? 0
                                                : timeItems.map((e) => e.value).indexOf(time)
                                            ]
                                        }
                                        value={
                                            timeItems[
                                            timeItems.map((e) => e.value).indexOf(time) === -1
                                                ? 0
                                                : timeItems.map((e) => e.value).indexOf(time)
                                            ]
                                        }
                                        onChange={(e, { value }) => {
                                            setTime(value.value);
                                        }}
                                    />

                                    {time === 2 ? (
                                        <> <div></div>
                                            <div className="custom-time-container">
                                                <div>
                                                    <label>Start Time</label>
                                                    {
                                                        context.teams.hostClientType.web ||
                                                            context.teams.hostClientType.desktop ? (
                                                            <TeamsTimePicker
                                                                required={true}
                                                                disabled={!editTime}
                                                                value={startTime}
                                                                onChange={(value) => {
                                                                    setStartTime(value);
                                                                }}
                                                            />
                                                        ) : (
                                                            <NativeTimePicker
                                                                id="startTime"
                                                                disabled={!editTime}
                                                                value={startTime}
                                                                onChange={(value) => {
                                                                    setStartTime(value);
                                                                }}
                                                                required={true}
                                                            />
                                                        )
                                                        // <FormField
                                                        //   className="timePicker"
                                                        //   control = {{ as: Input, value: startTime, type: 'time',
                                                        //     onChange: (e)=>{setStartTime(e.target.value)}
                                                        //   }}
                                                        // />
                                                    }
                                                </div>
                                                <div>
                                                    <label>End Time</label>
                                                    {
                                                        context.teams.hostClientType.web ||
                                                            context.teams.hostClientType.desktop ? (
                                                            <TeamsTimePicker
                                                                required={true}
                                                                disabled={!editTime}
                                                                min={startTime}
                                                                value={endTime}
                                                                onChange={(value) => {
                                                                    setEndTime(value);
                                                                }}
                                                            />
                                                        ) : (
                                                            <NativeTimePicker
                                                                id="endTime"
                                                                min={startTime}
                                                                disabled={!editTime}
                                                                value={endTime}
                                                                onChange={(value) => {
                                                                    setEndTime(value);
                                                                }}
                                                                required={true}
                                                            />
                                                        )
                                                        // <FormField
                                                        //   className="timePicker"
                                                        //   control = {{ as: Input, value: endTime, type: 'time',
                                                        //     onChange: (e)=>{setEndTime(e.target.value)}
                                                        //   }}
                                                        // />
                                                    }
                                                </div>
                                            </div>
                                        </>
                                    ) : (
                                        <></>
                                    )}
                                </div>}
                                <div className="dropdownSection mb-2 groupFilter">
                                    <FormCheckbox
                                        id="quickEditOrgCheck"
                                        label="Organization"
                                        value={editPeople}
                                        checked={editPeople}
                                        onChange={(e, { checked }) => {
                                            setEditPeople(checked); checked == true ? setOrganization(true) : setOrganization(false);
                                            //if (checked !== true) { handleGroupChange([]); handleUserChange([]); handleTeamChange([]); handleAgentChange([]); handleCallQueueChange([]); }
                                        }}
                                    />
                                    {/* <div> */}
                                    <FormDropdown
                                        id="quickEditOrgInput"
                                        fluid
                                        
                                        // label="People/Team/Group"
                                        disabled={!editPeople}
                                        items={context.loadExternalTeamsCallCards === true ? filterTypes : filterTypes.filter(f => !(f.value == 4 || f.value == 3))}
                                        value={FilterTypes[filterTypeId]}
                                        defaultValue={FilterTypes[filterTypeId]}
                                        onChange={(e, { value }) => {
                                            handleFilterTypeChange(value.value);
                                        }}
                                    />
                                    <div className="dropdownSection mb-3 groupFilter" />
                                    {filterTypeId === 0 && (
                                        <FormDropdown
                                            id="quickEditPeopleInput"
                                            fluid
                                            
                                            label="People"
                                            disabled={!editPeople}
                                            search
                                            multiple
                                            autoSize
                                            placeholder="Select People"
                                            items={dropDownOptions.filter(
                                                (uf) => !peopleMasKList.includes(uf.key)
                                            )}
                                            // items={filterData.userFilter.items.filter(
                                            //   (uf) => !peopleMasKList.includes(uf.key)
                                            // )}
                                            headerMessage={(
                                                <Checkbox
                                                    id="quickEditPeopleCheckAll"
                                                    checked={allUsers}
                                                    onChange={(e, { checked }) => { handleAllUsers(checked) }}
                                                    label={<Text content="Select All" weight="semibold" />}
                                                />)
                                            }
                                            defaultValue={[]}
                                            value={users}
                                            onChange={(e, { value }) => {
                                                handleUserChange(value);
                                            }}
                                            noResultsMessage="We couldn't find any matches."
                                            itemToValue={(obj) => {
                                                return obj.key;
                                            }}
                                            renderSelectedItem={(Component, props) => {
                                                if (props.key < 0) {
                                                    props.className = props.className + " _expandable";
                                                    props.onClick = (n, a) => {
                                                        if (
                                                            !n.currentTarget.classList.contains(
                                                                "ui-dropdown__selecteditem__icon"
                                                            )
                                                        ) {
                                                            setExpandPeople(props.key);
                                                        }
                                                    };
                                                }
                                                return <Component {...props} />;
                                            }}
                                        />
                                    )}
                                    {filterTypeId === 1 && <div></div>}
                                    {filterTypeId === 1 &&
                                        <FormCheckbox
                                            id="quickEditTeamTxt"
                                            className="checkBoxTeamisGroup align-items-center mt-0"
                                            style={{ fontSize: "12px" }}
                                            label="Include reporting on activity outside selected Teams."
                                            value={teamIsGroup}
                                            checked={teamIsGroup}
                                            disabled={teamIsGroupDisabled}
                                            onChange={(e, { checked }) => { onTeamIsGroup(checked) }}
                                        />
                                    }
                                    {filterTypeId === 2 && (
                                        <FormDropdown
                                            id="quickEditGroupsInput"
                                            fluid
                                            
                                            label="Groups"
                                            disabled={!editPeople}
                                            search
                                            multiple
                                            autoSize
                                            placeholder="Select Groups"
                                            items={filterData.groupFilter.items}
                                            headerMessage={(
                                                <Checkbox
                                                    id="quickEditGroupsCheckAll"
                                                    checked={allTeams}
                                                    onChange={(e, { checked }) => { handleAllGroups(checked) }}
                                                    label={<Text content="Select All" weight="semibold" />}
                                                />)
                                            }
                                            defaultValue={groups}
                                            value={groups}
                                            itemToValue={(obj) => {
                                                return obj.key;
                                            }}
                                            onChange={(e, { value }) => {
                                                handleGroupChange(value);
                                            }}
                                            noResultsMessage="We couldn't find any matches."
                                        />
                                    )}
                                    {filterTypeId === 3 && (
                                        <FormDropdown
                                            id="quickEditCallQInput"
                                            fluid
                                            
                                            label="Call Queues"
                                            disabled={!editPeople}
                                            search
                                            multiple
                                            autoSize
                                            placeholder="Select Call Queues"
                                            items={filterData.callQueueFilter.items}
                                            defaultValue={callQueues}
                                            value={callQueues}
                                            headerMessage={(
                                                <Checkbox
                                                    id="quickEditCallQCheckAll"
                                                    checked={allTeams}
                                                    onChange={(e, { checked }) => { handleAllcallQueues(checked) }}
                                                    label={<Text content="Select All" weight="semibold" />}
                                                />)
                                            }
                                            itemToValue={(obj) => {
                                                return obj.key;
                                            }}
                                            onChange={(e, { value }) => {
                                                handleCallQueueChange(value);
                                            }}
                                            noResultsMessage="We couldn't find any matches."
                                        />
                                    )}
                                    {filterTypeId === 4 && (
                                        <FormDropdown
                                            id="quickEditAgentsInput"
                                            fluid
                                            
                                            label="Agents"
                                            disabled={!editPeople}
                                            search
                                            multiple
                                            autoSize
                                            placeholder="Select Agents"
                                            items={filterData.agentFilter.items}
                                            defaultValue={agents}
                                            value={agents}
                                            headerMessage={(
                                                <Checkbox
                                                    id="quickEditAgentsCheckAll"
                                                    checked={allTeams}
                                                    onChange={(e, { checked }) => { handleAllAgents(checked) }}
                                                    label={<Text content="Select All" weight="semibold" />}
                                                />)
                                            }
                                            itemToValue={(obj) => {
                                                return obj.key;
                                            }}
                                            onChange={(e, { value }) => {
                                                handleAgentChange(value);
                                            }}
                                            // headerMessage={(
                                            //   <Checkbox
                                            //   checked={allAgents}
                                            //   onChange={(e, { checked }) => {handleAllAgents(checked)}}
                                            //   label={<Text content="Select All" weight="semibold" />}
                                            // />)
                                            //  }
                                            noResultsMessage="We couldn't find any matches."
                                        />
                                    )}
                                    {/* </div> */}
                                    {(filterTypeId === 1 && filterData.teamFilter.items.length === 0) ?
                                        <Text>You do not have permission to any team. Please get team permissions in settings</Text>
                                        : <></>
                                    }
                                    {(filterTypeId === 2 && filterData.groupFilter.items.length === 0) ?
                                        <Text>You do not have permission to any group. Please get group permissions in settings</Text>
                                        : <></>
                                    }
                                    {filterTypeId === 1 && (
                                        <FormDropdown
                                            id="quickEditTeamsInput"
                                            fluid
                                            
                                            label="Teams"
                                            disabled={!editPeople}
                                            search
                                            multiple
                                            autoSize
                                            placeholder="Select teams"
                                            items={teamIsGroup ? teamIsGroupList : teamIsNotGroupList}
                                            defaultValue={teams}
                                            headerMessage={(
                                                <Checkbox
                                                    id="quickEditTeamsCheckAll"
                                                    checked={allTeams}
                                                    onChange={(e, { checked }) => { handleAllTeams(checked) }}
                                                    label={<Text content="Select All" weight="semibold" />}
                                                />)
                                            }
                                            value={teams}
                                            itemToValue={(obj) => {
                                                return obj.key;
                                            }}
                                            onChange={(e, { value }) => {
                                                handleTeamChange(value);
                                            }}
                                            noResultsMessage="We couldn't find any matches."
                                        />
                                    )}
                                </div>
                            </div>
                            {loaded && (
                                <div style={{ width: "100%", padding: "0 1em" }}>
                                    <Grid
                                        data={
                                            orderBy(widgets != null
                                                ? widgets//.sort((a, b) => a.widgetName > b.widgetName ? 1 : -1)
                                                    .map((item) => ({
                                                        ...item,
                                                        [SELECTED_FIELD]: selectedState[idGetter(item)],
                                                    }))
                                                : null, sort)
                                        }
                                        sortable={true}
                                        sort={sort}
                                        onSortChange={sortChange}
                                        style={{
                                            maxHeight: "100%",
                                        }}
                                        dataItemKey={DATA_ITEM_KEY}
                                        selectedField={SELECTED_FIELD}
                                        selectable={{
                                            enabled: true,
                                            drag: false,
                                            cell: false,
                                        }}
                                        onSelectionChange={selectionChange}
                                        onHeaderSelectionChange={headerSelectionChange}
                                        detail={DetailComponent}
                                        expandField="expanded"
                                    >
                                        <GridNoRecords>
                                            {widgets != null ? (
                                                "No cards found for the filters selected."
                                            ) : (
                                                <Loader />
                                            )}
                                        </GridNoRecords>
                                        <Column
                                            field="checked"
                                            width="32px"
                                            headerSelectionValue={
                                                widgets.findIndex(
                                                    (item) => !selectedState[idGetter(item)]
                                                ) === -1
                                            }
                                        />
                                        <Column
                                            field="name"
                                            title="Card Name"
                                            width={
                                                context.mediaCategory.sm
                                                    ? "calc(100% - 172px)"
                                                    : "calc(100% - 302px)"
                                            }
                                        />
                                        <Column
                                            field="widgetName"
                                            width={
                                                context.mediaCategory.sm
                                                    ? "calc(100% - 172px)"
                                                    : "calc(100% - 302px)"
                                            }
                                            title="Card"
                                        />
                                        <Column
                                            width="60px"
                                            title="View"
                                            cell={ViewCell}
                                            headerCell={CenteredHeader}
                                        />
                                        {!context.mediaCategory.sm && (
                                            <Column
                                                width="130px"
                                                title="Type"
                                                field="collaborationTypeID"
                                                cell={CellWithTypeIcon}
                                            />
                                        )}
                                    </Grid>
                                </div>
                            )}
                        </div>
                        <Flex className="align-items-center " gap="gap.small" fill hAlign="end" style={{ height: "auto" }}>
                            {activeIndex !== 0 && isDemo && <div className="col text-right px-0">Exploring app with sample data.</div>}
                            {activeIndex === 0 ? (
                                <Button
                                    id="quickEditCancel"
                                    content="Cancel"
                                    onClick={() => props.cancelHandler()}
                                />
                            ) : activeIndex !== 0 ? (
                                <Button id="quickEditBack" content="Back" onClick={onPrevious} />
                            ) : (
                                <></>
                            )}
                            {activeIndex === 0 ? (
                                <Button
                                    disabled={!((organization && isValid) || ((editTime || editPeriod) && (!organization || (organization && isValid))))}
                                    id="quickEditNext"
                                    primary
                                    content="Next"
                                    onClick={() => onNext(time)}
                                />
                            ) : isDemo ? (
                                <FormButton
                                    id="quickEditSaveDemo"
                                    disabled={isSaveEnabled === false || submitDemoButtonState !== 0}
                                    icon={submitDemoButtonState === 2 && <AcceptIcon />}
                                    loading={submitDemoButtonState === 1}
                                    content={"Save"}
                                    primary
                                    onClick={() => handleClick()}
                                // style={isSaveEnabled ? {
                                //   color: "#212529",
                                //   backgroundColor: "#ffc107",
                                //   borderColor: "#ffc107"
                                // } : null}
                                />
                            ) : activeIndex === 1 ? (
                                <FormButton
                                    id="quickEditSave"
                                    disabled={isSaveEnabled === false || submitButtonState !== 0 || isDemo}
                                    icon={submitButtonState === 2 && <AcceptIcon />}
                                    loading={submitButtonState === 1}
                                    content={
                                        submitButtonState === 0
                                            ? "Save"
                                            : submitButtonState === 1
                                                ? "Saving"
                                                : submitButtonState === 2
                                                    ? "Saved"
                                                    : "Failed"
                                    }
                                    primary
                                    onClick={() => handleClick()}
                                />
                            ) : (
                                <></>
                            )}
                        </Flex>
                    </Form>

                    <FluentDialog
                        style={{ maxWidth: "300px" }}
                        backdrop={true}
                        open={open}
                        footer={{
                            children: (Component, props) => {
                                const { styles, ...rest } = props;
                                return (
                                    <Flex styles={styles} hAlign="center" gap="gap.small">
                                        <Button size="small" content="Cancel" onClick={onCancel} />
                                        <Button
                                            size="small"
                                            primary
                                            content="Ok"
                                            onClick={onConfirm}
                                        />
                                    </Flex>
                                );
                            },
                        }}
                        content={
                            <>
                                <Flex
                                    fill
                                    hAlign="center"
                                    style={{ paddingBottom: "15px" }}
                                    gap="gap.small"
                                >
                                    <ExclamationTriangleIcon
                                        size="largest"
                                        styles={{ color: "#fce100" }}
                                    />
                                    <Flex.Item>
                                        <Text content="If you go back, your selection will be lost." />
                                    </Flex.Item>
                                </Flex>
                            </>
                        }
                    />

                    <FluentDialog
                        style={{ maxWidth: "300px" }}
                        backdrop={true}
                        open={expandPeople < 0}
                        footer={{
                            children: (Component, props) => {
                                const { styles, ...rest } = props;
                                return (
                                    <Flex styles={styles} hAlign="center" gap="gap.small">
                                        <Button
                                            size="small"
                                            content="Cancel"
                                            onClick={onExpandCancel}
                                        />
                                        <Button
                                            size="small"
                                            primary
                                            content="Expand"
                                            onClick={onExpandConfirm}
                                        />
                                    </Flex>
                                );
                            },
                        }}
                        content={
                            <>
                                <Flex
                                    fill
                                    hAlign="center"
                                    style={{ paddingBottom: "15px" }}
                                    gap="gap.small"
                                >
                                    <ExclamationTriangleIcon
                                        size="largest"
                                        styles={{ color: "#fce100" }}
                                    />
                                    <Flex.Item>
                                        <Text content="If you expand the list, the list will be replaced by individual members. It is not possible to collapse the list again." />
                                    </Flex.Item>
                                </Flex>
                            </>
                        }
                    />
                    {openPreview && (
                        <Dialog
                            autoFocus={true}
                            className="previewDialog"
                            title={previewObj.widgetName}
                            onClose={() => setOpenPreview(false)}
                        >
                            <WidgetPreview
                                widgetID={previewObj.cardWidgetID}
                                viewTypeID={previewObj.cardViewTypeID}
                            />
                        </Dialog>
                    )}
                </Fragment>
            )}
        </WindowContext.Consumer>
    );
};

export default MultiCardSettingsEdit;
