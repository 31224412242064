const MockData = {


    7: {
        description: 
`Use this card to identify issues with network quality. This is especially useful for highly distributed businesses.

View a summary of network quality across selected people, rated as a percentage good, average or poor.

Data: Network quality score, rated by Microsoft.`,
        data: [
            {
                name: "Good",
                share: 37,
                color: "#4bc0c0",
                total: 15,
            },
            {
                name: "Average",
                share: 47,
                color: "#ffcd56",
                total: 19,
            },
            {
                name: "Poor",
                share: 16,
                color: "#ff6384",
                total: 5,
            },
        ],
    },

    8: {
        description:
`Use this card to help understand overall message sentiment and monitor well-being.

Evaluate message sentiment across chat, posts and replies, presented as a positive, neutral or negative score.

Data: Message sentiment score based on all chat, posts and replies, as rated by Microsoft.`,
        data: [
            {
                name: "Positive",
                share: 56,
                color: "#4bc0c0",
                total: 22,
            },
            {
                name: "Neutral",
                share: 37,
                color: "#ffcd56",
                total: 14,
            },
            {
                name: "Negative",
                share: 7,
                color: "#ff6384",
                total: 4,
            },
        ],
    },

    9: {
        description:
`Use this card to understand sentiment through reactions across messages.
            
View a breakdown of reactions in messages (chat, group chat, posts and replies) for the selected individuals, groups or Teams.

Data : Reactions across messages (chat, group chat, posts and replies).`,     
        data: [
            {
                reactionName: "Like",
                reactionCount: 988,
            },
            {
                reactionName: "Heart",
                reactionCount: 166,
            },
            {
                reactionName: "Laugh",
                reactionCount: 235,
            },
            {
                reactionName: "Surprised",
                reactionCount: 22,
            },
            {
                reactionName: "Others",
                reactionCount: 2,
            },           
        ],
    },

    10: {
        description:
`Use this card to understand levels of collaboration activity each day. We recommend using this card to review an individual’s capacity; do they have the capacity to deliver their core output or are they at risk of burnout?

Apply the working hours filter to exclude weekends in the graph, or apply the outside working hours filter for the alternative view.

Analyse daily collaboration activity trends for selected people or your whole team.

Data: Total collaboration time in meetings/calls and messages`,
        data: {
            categories: [
                "18/5/2021",
                "19/5/2021",
                "20/5/2021",
                "21/5/2021",
                "22/5/2021",
                //"23/5/2021",
                //"24/5/2021",
            ],
            //series: [
            //    {name: "Chat", data: [3973, 4859, 3639, 3944, 788, 410, 1826], color: "#ffcd56"},
            //    {name: "Post", data: [13, 24, 2, 0, 3, 10, 5], color: "#ff9f40"},
            //    {name: "Meetings/Calls", data: [240, 287, 264, 261, 7, 20, 83], color: "#4bc0c0"}
            //    // {name: "Meetings", data: [12, 17, 9, 3, 7, 16, 22], color: "#8a8bd4"}
            //],
            series: [
                { name: "Chats", data: [240, 322, 500, 775, 401], color: "#ffcd56" },
                { name: "Posts/Replies", data: [12, 13, 23, 18, 15], color: "#ff9f40" },
                { name: "Meetings/Calls", data: [25, 26, 45, 48, 3], color: "#4bc0c0" }
                // {name: "Meetings", data: [12, 17, 9, 3, 7, 16, 22], color: "#8a8bd4"}
            ],
        },
    },

    11: {
        description:
`Use this card to review trends in network quality to monitor internal and external experience. We recommend filtering by poor quality to highlight those days where poor network quality occurred in Teams. Use the Network Quality by People card to filter by those days, to see who was affected.
            
Review daily network quality trends for your selected people, rated as good, average or poor.

Data : Network quality score, rated by Microsoft.`,
        data: {
            categories: [
                "18\\5\\2021",
                "19\\5\\2021",
                "20\\5\\2021",
                "21\\5\\2021",
                "22\\5\\2021",
                "23\\5\\2021",
                "24\\5\\2021",
            ],
            series: [
                {
                    name: "Good",
                    data: [122, 136, 130, 130, 2, 9, 41],
                    color: "#4bc0c0",
                },
                {
                    name: "Average",
                    data: [110, 111, 125, 108, 5, 9, 42],
                    color: "#ffcd56",
                },
                {
                    name: "Poor",
                    data: [31, 64, 34, 39, 1, 2, 7],
                    color: "#ff6384",
                },
            ],
        },
    },

    13: {
        description:
`Use this card to review messaging trends when profiling engagement or assessing well-being.
            
Review daily message trends for selected people, Teams or groups, summarising all messaging across chat, posts and replies.

Data: Messages (Chat, posts and replies).`,
        data: {
            categories: [
                "18\\5\\2021",
                "19\\5\\2021",
                "20\\5\\2021",
                "21\\5\\2021",
                "24\\5\\2021",
            ],
            series: [
                {
                    name: "Messages",
                    data: [3165, 1708, 2550, 2408, 2707],
                    color: "#36a2eb",
                },
            ],
        },
    },

    15: {
        description:
`Use this card to review a full summary of collaboration across a group of selected people and sort by the method of engagement that is most of interest. Understand the profile of activity across the selection of people to establish best practices of engagement.
            
Compare profiles of collaboration across calls, meetings and messages. Collaboration is summarised to show the proportion of activity that is internal (ie. involving only people within your business), external (including external parties), organised and recurring. 

Data: Calls, Meetings, Messages (chat, posts, replies and sentiment).`,
        data: [
            {
                displayName: "Jake Chambers",
                videoCalls: 2,
                audioCalls: 124,
                phoneCalls: 10,
                totalCalls: "32(21)",
                talkTime: "1d, 00:35:19",
                teamCallsMeetings: 20,
                totalMeetings: "23(2)",
                perExternalMeetings: 12,
                perOrganizedMeetings: 18,
                perRecurringMeetings: 5,
                totalTimeInMeetings: "0d, 04:43:50",
                chats: 255,
                posts: 0,
                replies: 255,
                sentimentScore: "Positive"
            },
            {
                displayName: "Susannah Dean",
                videoCalls: 5,
                audioCalls: 65,
                phoneCalls: 14,
                teamCallsMeetings: 100,
                totalCalls: "119(26)",
                talkTime: "0d, 10:20:00",
                totalMeetings: "64(10)",
                perExternalMeetings: 40,
                perOrganizedMeetings: 62,
                perRecurringMeetings: 23,
                totalTimeInMeetings: "0d, 02:36:53",
                chats: 523,
                posts: 20,
                replies: 42,
                sentimentScore: "Negative"
            },
            {
                displayName: "Richie Tozier",
                videoCalls: 32,
                audioCalls: 53,
                phoneCalls: 3,
                teamCallsMeetings: 20,
                totalCalls:"55(10)",
                talkTime: "0d, 06:43:50",
                totalMeetings: "60(2)",
                perExternalMeetings: 52,
                perOrganizedMeetings: 47,
                perRecurringMeetings: 36,
                totalTimeInMeetings: "0d, 03:48:21",
                chats: 175,
                posts: 4,
                replies: 94,
                sentimentScore: "Positive"
            },
            {
                displayName: "Wendy Torrence",
                videoCalls: 24,
                audioCalls: 85,
                phoneCalls: 31,
                teamCallsMeetings:112,
                totalCalls: "167(40)",
                talkTime: "1d, 02:36:53",
                totalMeetings: "83(22)",
                perExternalMeetings: 18,
                perOrganizedMeetings: 49,
                perRecurringMeetings: 24,
                totalTimeInMeetings: "0d, 05:48:21",
                chats: 274,
                posts: 34,
                replies: 95,
                sentimentScore: "Neutral"
            },
            {
                displayName: "Stuart Redman",
                videoCalls: 31,
                audioCalls: 115,
                phoneCalls: 12,
                teamCallsMeetings:102,
                totalCalls:"145(12)",
                talkTime: "1d, 05:48:21",
                totalMeetings: "94(0)",
                perExternalMeetings: 62,
                perOrganizedMeetings: 39,
                perRecurringMeetings: 23,
                totalTimeInMeetings: "0d, 01:14:21",
                chats: 304,
                posts: 27,
                replies: 41,
                sentimentScore: "Positive"
            }
        ],
    },



    29: {
        description:
`Use this card to understand interactions and to see if your people are in contact with the people they need to be liaising with. 
            
View a visual representation of an individual’s collaboration network, showing intensity of collaboration across all forms of one-to-one interaction.

Data: One-to-one collaboration time in meetings, calls and chat.`,
        data: {
            userDetails: {userName: "Stuart Redman", image: "", jobTitle: "Director"},
            collaborationData: [
                { userName: "Abdiel Jimenez", image: "", jobTitle: "CTO", collaborationTime: "05:48:21", category: "High" },
                { userName: "Chavron Tim", image: "", jobTitle: "GM", collaborationTime: "05:40:31", category: "High" },
                { userName: "Veri Tasium", image: "", jobTitle: "CMO", collaborationTime: "05:32:26", category: "High" },
                { userName: "Pearl Rodriquez", image: "", jobTitle: "Client Manager", collaborationTime: "05:31:19", category: "High" },
                { userName: "Derick Hayden", image: "", jobTitle: "CEO", collaborationTime: "05:30:15", category: "High" },
                { userName: "Carl Roberts", image: "", jobTitle: "Assistant Manager", collaborationTime: "05:29:24", category: "High" },
                { userName: "Rupert Grant", image: "", jobTitle: "CFO", collaborationTime: "05:25:24", category: "High" },
                { userName: "Vlad Koschenko", image: "", jobTitle: "Manager", collaborationTime: "05:20:26", category: "High" },
                { userName: "Courtney Stanley", image: "", jobTitle: "Product Manager", collaborationTime: "04:50:32", category: "High" },
                { userName: "Marc Roberts", image: "", jobTitle: "Software Developer", collaborationTime: "03:59:25", category: "High" },
                { userName: "Nikolai Ross", image: "", jobTitle: "Platform Developer", collaborationTime: "01:05:43", category: "Medium" },
                { userName: "Peter Rick", image: "", jobTitle: "Systems Manager", collaborationTime: "01:04:41", category: "Medium" },
                { userName: "Dave Sharma", image: "", jobTitle: "Manager", collaborationTime: "01:01:24", category: "Medium" },
                { userName: "Mark Smith", image: "", jobTitle: "Software Developer", collaborationTime: "01:00:43", category: "Medium" },
                { userName: "Tim West", image: "", jobTitle: "QA TEster", collaborationTime: "00:58:43", category: "Medium" },
                { userName: "Unger Jones", image: "", jobTitle: "Software Architect", collaborationTime: "00:57:43", category: "Medium" },
                { userName: "James", image: "", jobTitle: "Business Analyst", collaborationTime: "00:56:54", category: "Medium" },
                { userName: "Hugo Weaving", image: "", jobTitle: "Team Lead", collaborationTime: "00:32:11", category: "Low" },
                { userName: "John Hurt", image: "", jobTitle: "Data Analyst", collaborationTime: "00:27:42", category: "Low" },
                { userName: "Stephen Rea", image: "", jobTitle: "Backend Developer", collaborationTime: "00:15:13", category: "Low" },
                { userName: "Rupert Graves", image: "", jobTitle: "Marketing Head", collaborationTime: "00:12:51", category: "Low" },
                { userName: "Wes Anderson", image: "", jobTitle: "Marketing Head", collaborationTime: "00:11:49", category: "Low" },
                { userName: "Clive Thomas", image: "", jobTitle: "Public Relations", collaborationTime: "00:10:50", category: "Low" },
                { userName: "Robert Galoway", image: "", jobTitle: "Social Media Head", collaborationTime: "00:09:31", category: "Low" },
                { userName: "Andy Carlson", image: "", jobTitle: "Title Manager", collaborationTime: "00:09:11", category: "Low" },
                { userName: "Amit Kumar", image: "", jobTitle: "Engineering Manager", collaborationTime: "00:07:32", category: "Low" },
                { userName: "Jesse Pinkman", image: "", jobTitle: "Product Head", collaborationTime: "00:04:19", category: "Low" },
                { userName: "Tucker Pound", image: "", jobTitle: "Marketing Intern", collaborationTime: "00:03:24", category: "Low" },
                { userName: "James Roberts", image: "", jobTitle: "Solutions Head", collaborationTime: "00:02:13", category: "Low" },
                { userName: "Travis Tesfaye", image: "", jobTitle: "Relations Head", collaborationTime: "00:01:11", category: "Low" }
            ]
        }
    },

    //31: {
    //    description:
    //        "Comparison of Team chats versus direct one-to-one chats across your data period and people selection.",
    //    data: {
    //        categories: [
    //            "Abdiel Jimenez",
    //            "Derick Hayden",
    //            "Courtney Stanley",
    //            "Marc Roberts",
    //            "Nikolai Ross",
    //            "Ashlyn Houston",
    //            "Dave Sharma",
    //            "Charlotte Mcintyre",
    //            "Sofia Porter",
    //            "Britney Hooper",
    //            "Lexie Willis",
    //            "Fabian Haley",
    //            "Sunil Nevla",
    //            "Paul Rovia",
    //            "Kaley Cannon",
    //            "Alfred Evans",
    //            "Irvin Norton",
    //            "Lisa Munns",
    //            "Dante Cardenas",
    //            "Areli Singh",
    //            "Cailyn Ramsey",
    //            "Jordan Green",
    //            "Kelly Grayson",
    //            "Rosario Perrone",
    //            "Rylie Austin",
    //            "Talan Michael",
    //            "Selina Mughal",
    //            "Annie Wilkes",
    //            "Ashok Sharma",
    //            "Laurel Parsons",
    //            "Natalie Cook",
    //            "Maya Sharma",
    //        ],
    //        series: [
    //            {
    //                name: "Team",
    //                data: [
    //                    1, 10, 10, 3, 2, 0, 0, 0, 2, 1, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 2, 0,
    //                    0, 0, 0, 0, 0, 0, 16, 0, 0, 0,
    //                ],
    //                color: "#32007D",
    //            },
    //            {
    //                name: "Chat",
    //                data: [
    //                    120, 74, 828, 134, 49, 76, 79, 61, 343, 116, 72, 39, 383, 49, 177,
    //                    111, 89, 7, 17, 61, 69, 515, 124, 532, 16, 47, 192, 19, 228, 35, 95,
    //                    175,
    //                ],
    //                color: "#AA75C1",
    //            },
    //        ],
    //    },
    //},



    2: {
        description:
`Use this card to view an overview of a selected Team. Gain an understanding of total channels, active users, latest activity, meetings and messages.

Data: Team profile, Team meetings and messages.
`,
        data: {
            groupName : "Knowledge Management",
            totalChannels: 3,
            totalUsers: 21,
            memberGuests: 19,
            owners: 2,
            activeOwnersMembers: "17(2)",
            inactiveOwnersMembers: "2(0)",
            externalMembers: 2,
            totalMeetings: "8(1)",
            totalMessages: "52(10)",
            totalMentions: "10(2)",
            totalReactions: "12(0)",
            latestActivityTime: "05:30 AM",
            latestActivitySubject: "Call By Nick",
            nextMeetingTime: "08/31/2022 1:00:00 PM",
            nextMeetingSubject: "Standup Meeting",
            nextMeetingOrganiser: "Knowledge Management",
        },
    },

    3: {
        description:

`Use this card to review scheduled meeting trends when profiling engagement, to assess wellbeing or to understand levels of external customer engagement.

Review scheduled meeting activity for selected people, Teams or groups to understand meeting habits, time in meetings, number of external meetings and meetings organised vs. participated. Meeting habits include percentage of meetings that overran, were attended, time spent in meetings and percentage with external participants.

Data: Scheduled Meetings (Organised, percentage external, meeting habits and time in meetings).`,
        data: {
            totalOrganized: 204,
            internalOrganized: 201,
            externalOrganized: 3,
            totalParticipated: 56,
            internalParticipated: 52,
            externalParticipated: 4,
            totalTimeInMeetings: "2d, 19:44:46",
            internalTimeInMeetings: "2d, 15:01:24",
            externalTimeInMeetings: "0d, 04:43:22",
            externalParticipation: 7,
            totalRecurringMeeting: 4,
            totalCountScheduled: 0,
            totalExternalParticipation: 4,
            totalScheduled: 0,
            recurringMeetings: 7,
            totalOverrun: 32,
            timeSpentOnMeeting: 94,
            attended: 31,
            overrun: 17,
            totalPerMeetings: 154,
            perMeetings: 60,
        },
    },

    4: {
        description:
`Use this card to understand behaviour and habits across people to contribute to wellbeing policy, best practice reviews and performance evaluation.

Display meeting habits for a selection of people or an individual. Understand how many invites are sent at short notice, what percentage of invitees join, how much conversation happens outside of the meeting, if meetings join on time and how many are organised outside of working hours.
 
Data: Scheduled meetings.`,
        data: {
            atLeastTwentyFourHoursNotice: 75,
            attendance: 50,
            noMessageToPeopleOutsideMeeting: 14,
            joinedBeforeMeetingStarts: 76,
            outsideWorkingHours: 20,
            overrun: 37,
            noOverlap: 37,
            isTeam: false
        },
    },

    19: {
        description:
`Use this card to monitor activity, engagement or to profile people in similar roles to implement best practice and support well-being.

Compare collaboration activity of two people, across meetings, calls, messages and Teams.
            
Data: Meetings, calls, messages and Teams.`,
        data: {
            displayName1:"Jake Chambers",
            displayName2:"Susannah Dean",
            "initials1" :"JC",
            "initials2" :"SD",
            image :"",
            image1 :"",
            timeInMeetings :"05:05:05",
            meetingsTotal :"85",
            meetingsOrganizedExternal :"45",
            meetingsOrganized1To1 :"5",
            meetingsOrganizedOOH :"45",
            meetingsOrganizedVideo :"5",
            totalCallTalktime:"05:05:05", 
            totalCalls :"87",
            totalCallsOrganized :"35",
            callsExternal :"5",
            calls1To1 :"52",
            callsOOH :"0",
            CallsVideo :"0",
            totalTeamMemberships :"21",
            messagesTotal :"100",
            messagesExternal :"55",
            messages1To1 :"5",
            messagesOOH :"15",
            messagesWithinTeam :"5",
            timeInMeetings1 :"05:55:57",
            meetingsTotal1 :"61",
            meetingsOrganizedExternal1 :"52",
            meetingsOrganized1To11 :"5",
            meetingsOrganizedOOH1 :"50",
            meetingsOrganizedVideo1 :"45",
            totalCallTalktime1 :"01:00:55",
            totalCalls1 :"55",
            totalCallsOrganized1:"55",
            callsExternal1 :"85",
            calls1To11 :"85",
            callsOOH1 :"75",
            CallsVideo1 :"45",
            totalTeamMemberships1:"5" ,
            messagesTotal1 :"45",
            messagesExternal1 :"5",
            messages1To11 :"45",
            messagesOOH1 :"5",
            messagesWithinTeam1: "57",
            messagesPosts1: "20",
            messagesPosts2: "23",
            messagesChats1 : "8",
            messagesChats2: "10",
            messagesMentions1: "15",
            messagesMentions2: "17",
            messagesReplies1: "6",
            messagesReplies2: "8",
            recurrence1: "14",
            recurrence2: "19",
            sentiment1: "Neutral",
            sentiment1: "Positive",
            goodSentiment1: "20",
            poorSentiment1: "50",
            neutralSentiment1: "30",
            goodSentiment2: "60",
            poorSentiment2: "6",
            neutralSentiment2: "34",
            totalCallsOrganized: "16",
            totalCallsOrganized1: "32",
            activeChannels: "18",
            activeChannels1: "24",
            meetingsByOrganizer: "45",
            meetingsByOrganizer1: "39",
            totalOwners: "0",
            totalOwners1: "2"

        },
    },

    16: {
        description:
`Use this card to monitor meeting participation by assessing engagement of invited attendees and relevance of the meeting.

High level summary of attendance at scheduled meetings for your selected people and date range, based on invitees to a meeting.

Data: Scheduled meetings.`,
        data: 67,
    },

    34: {
        description:
`Use this card to understand how people engage using messaging, their overall sentiment and profile.

Compare messaging profiles of activity across selected people, by chat, posts, message replies and sentiment. Gain an understanding of the use of chat to external contacts and how often messaging is used while on meetings/calls.

Data: Message type, external messages, sentiment score by Microsoft and messages while on a meeting/call.`,
        data: {
            gridData:
                [
                    {
                        userName: "Suhana Dhar",
                        internal: 154,
                        external: 42,
                        totalMessages: 196,
                        chats: 130,
                        posts: 21,
                        replies: 15,
                        oneToOne: 30,
                        sentiment: 'Positive',
                        perPositive: 35,
                        messageRecievedWhileBusy: 23
                    },
                    {
                        userName: "Rick Tomar",
                        internal: 296,
                        external: 34,
                        totalMessages: 330,
                        chats: 211,
                        posts: 32,
                        replies: 19,
                        oneToOne: 46,
                        sentiment: 'Neutral',
                        perPositive: 54,
                        messageRecievedWhileBusy: 27
                    },
                    {
                        userName: "Steve Raman",
                        internal: 104,
                        external: 33,
                        totalMessages: 137,
                        chats: 93,
                        posts: 11,
                        replies: 12,
                        oneToOne: 53,
                        sentiment: 'Positive',
                        perPositive: 27,
                        messageRecievedWhileBusy: 19
                    }
                ],
            stackedBarChartData:
            {
                categories: [
                    "Suresh Naval",
                    "Rick Tomar",
                    "Steve Raman"
                ],
                series: [
                    {
                        name: "Chat",
                        data: [
                            130, 211, 93
                        ],
                        color: "#ffcd56",
                    },
                    {
                        name: "Posts",
                        data: [
                            21, 32, 11
                        ],
                        color: "#ff9f40",
                    },
                    {
                        name: "Replies",
                        data: [
                            15, 19, 12
                        ],
                        color: "#8a8bd4",
                    },
                ],
            }
        }

    },


   
    35: {
        description:
`Filter this card to view Team and Channel profiles, activity, external engagement and overall sentiment over a selected time period. Sort columns to review overall Team activity, amount of external participation in that Team, average sentiment and spot inactive Teams.
            
Expand the Team to view a breakdown by channel. 

Data: Team and Channel meetings, posts and replies, active users (including external members) and message sentiment (rated by Microsoft).
`,
        data: {
            // insights: {
            //     perEngagedExternally: 20,
            //     perScreenShare: 25,
            //     perFileShare: 35,
            //     activeUsers: 100
            // },
            gridData: [
                {
                    teamId: 1,
                    teamName: "Internal Communications",
                    organizedMeetings: 10,
                    organizedMeetingMinutes: "8:08:00",
                    perRecurring: "20(17%)",
                    externalMeeting: 0,
                    externalMeetingParticipation: "00:00:00",
                    perRecurringMinutes: 25,
                    perPositiveMessages: 60,
                    perNeturalMessages: 30,
                    perNegativeMessages: 10,
                    posts: "15(5)",
                    replies: "9(2)",
                    sentimentScore: "Positive",
                    activeUsers: 6,
                    inactiveUsers:5,totalUsers:"4(1)",membersOwners: "5(3)",
                    channelDetails: [
                        {
                            channelName: "General",
                            organizedMeetings: 4,
                            organizedMeetingMinutes: "1:38:51",
                            externalMeeting: 0,
                            externalMeetingParticipation: "00:00:00",
                            perRecurring: "15(5%)",
                            perRecurringMinutes: 20,
                            perPositiveMessages: 60,
                    perNeturalMessages: 20,
                    perNegativeMessages: 20,
                            posts: "5(1)",
                            replies: "4(2)",
                            sentimentScore: "Positive",
                            activeUsers: 6,
                    inactiveUsers:5,totalUsers:"4(1)",membersOwners: "5(3)"
                        },
                        {
                            channelName: "Daily Updates",
                            organizedMeetings: 3,
                            organizedMeetingMinutes: "1:15:37",
                            externalMeeting: 0,
                            externalMeetingParticipation: "00:00:00",
                            perRecurring: "3(100%)",
                            perRecurringMinutes: 70,
                            perPositiveMessages: 60,
                    perNeturalMessages: 10,
                    perNegativeMessages: 30,
                    posts: "3(1)",
                    replies: "7(2)",
                            sentimentScore: "Neutral",
                            activeUsers: 5,
                    inactiveUsers:5,totalUsers:"4(0)",membersOwners: "5(3)"

                        },
                        {
                            channelName: "Internal Team",
                            organizedMeetings: 3,
                            organizedMeetingMinutes: "0:59:12",
                            externalMeeting: 0,
                            externalMeetingParticipation: "00:00:00",
                            perRecurring: "1(30%)",
                            perRecurringMinutes: 10,
                            perPositiveMessages: 20,
                    perNeturalMessages: 40,
                    perNegativeMessages: 40,
                    posts: "7(6)",
                    replies: "10(2)",
                            sentimentScore: "Positive",
                            activeUsers: 6,
                    inactiveUsers:5,totalUsers:"4(0)",membersOwners: "5(3)"

                        },
                    ]
                },
                {
                    teamId: 2,
                    teamName: "Knowledge Management",
                    organizedMeetings: 5,
                    organizedMeetingMinutes: "5:00:00",
                    externalMeeting: 1,
                    externalMeetingParticipation: "1:00:00",
                    perRecurring: "5(40%)",
                    perRecurringMinutes: 25,
                    perPositiveMessages: 50,
                    perNeturalMessages: 20,
                    perNegativeMessages: 30,
                    posts: "2(1)",
                            replies: "4(0)",
                    sentimentScore: "Neutral",
                    activeUsers: 8,
                    inactiveUsers:5,totalUsers:"6(2)",membersOwners: "4(3)",
                    channelDetails: [
                        {
                            channelName: "General",
                            organizedMeetings: 2,
                            organizedMeetingMinutes: "0:39:51",
                            externalMeeting: 1,
                            externalMeetingParticipation: "1:00:00",
                            perRecurring: "1(50%)",
                            perRecurringMinutes: 34,
                            perPositiveMessages: 70,
                    perNeturalMessages: 10,
                    perNegativeMessages: 20,
                    posts: "5(1)",
                    replies: "4(2)",
                            sentimentScore: "Neutral",
                            activeUsers: 5,
                    inactiveUsers:5,totalUsers:"5(1)",membersOwners: "4(3)"

                        },
                        {
                            channelName: "Documents",
                            organizedMeetings: 3,
                            organizedMeetingMinutes: "4:21:09",
                            externalMeeting: 0,
                            externalMeetingParticipation: "00:00:00",
                            perRecurring: "1(33%)",
                            perRecurringMinutes: 20,
                            perPositiveMessages: 50,
                    perNeturalMessages: 20,
                    perNegativeMessages: 30,
                    posts: "9(1)",
                    replies: "1(0)",
                            sentimentScore: "Positive",
                            activeUsers: 7,
                    inactiveUsers:5,totalUsers:"3(1)",membersOwners: "4(3)"
                        },
                    ]
                },
                {
                    teamId: 3,
                    teamName: "R&D Team",
                    organizedMeetings: 12,
                    organizedMeetingMinutes: "09:22:05",
                    externalMeeting: 0,
                    externalMeetingParticipation: "00:00:00",
                    perRecurring: "1(50%)",
                    perRecurringMinutes: 38,
                    perPositiveMessages: 90,
                    perNeturalMessages: 10,
                    perNegativeMessages: 0,
                    posts: "4(1)",
                            replies: "7(2)",
                    sentimentScore: "Positive",
                    activeUsers: 10,
                    inactiveUsers:5,totalUsers:"4(2)",membersOwners: "5(2)",
                    channelDetails: [
                        {
                            channelName: "General",
                            organizedMeetings: 5,
                            organizedMeetingMinutes: "3:57:41",
                            externalMeeting: 0,
                            externalMeetingParticipation: "00:00:00",
                            perRecurring: "1(50%)",
                            perRecurringMinutes: 16,
                            perPositiveMessages: 80,
                    perNeturalMessages: 10,
                    perNegativeMessages: 10,
                    posts: "5(1)",
                    replies: "4(2)",
                            sentimentScore: "Positive",
                            activeUsers: 8,
                    inactiveUsers:5,totalUsers:"4(2)",
                    membersOwners: "5(2)"
                        },
                        {
                            channelName: "R&D Tasks",
                            organizedMeetings: 7,
                            organizedMeetingMinutes: "5:25:51",
                            externalMeeting: 0,
                            externalMeetingParticipation: "00:00:00",
                            perRecurring: "3(60%)",
                            perRecurringMinutes: 29,
                            perPositiveMessages: 20,
                    perNeturalMessages: 60,
                    perNegativeMessages: 20,
                    posts: "2(1)",
                    replies: "4(2)",
                            sentimentScore: "Neutral",
                            activeUsers: 9,
                    inactiveUsers:5,totalUsers:"4(0)",
                    membersOwners: "5(2)"
                        }
                    ]
                },
                // {
                //     teamId: 4,
                //     teamName: "Support Team",
                //     organizedMeetings: 0,
                //     perRecurring: 0,
                //     posts: 5,
                //     replies: 2,
                //     sentimentScore: "Neutral",
                //     activeUsers: 5,
                //     channelDetails: [
                //         {
                //             channelName: "General",
                //             organizedMeetings: 0,
                //             perRecurring: 0,
                //             posts: 2,
                //             replies: 0,
                //             sentimentScore: "Neutral",
                //             activeUsers: 5
                //         },
                //         {
                //             channelName: "Active Tickets",
                //             organizedMeetings: 0,
                //             perRecurring: 0,
                //             posts: 3,
                //             replies: 2,
                //             sentimentScore: "Neutral",
                //             activeUsers: 4
                //         }
                //     ]
                // }
            ]
        } 
    },


    37: {
        description:
`Use this card to compare call activity profiles for people within selected groups.

Select group(s) in the filter to display a quick glance summary of calls by people within that group, broken down by type, to easily see the proportion of Teams meetings/calls versus phone calls and percentage usage of video in Teams.

The table shows a full overview of call and meeting activity across the people in your selected groups, time period and time of day, broken down by one-to-one and group. Compare total calls, talk time, Teams meetings/calls and phone calls and see the percentage of teams calls that took place using video. Understand how many calls are one-to-one versus those co-attended by more than 2 participants.

Data: Teams calls and meetings, and phone calls.`,
        data: {
            groupedGridData:
                [
                    {
                        userName: "Database Team",
                        oneToOne: {
                            calls: '754(51)',
                            talkTime: "12:49:00",
                            video: 231,
                            audio: 1641,
                        },
                        group: {
                            calls: '1087(180)',
                            talkTime: "03:11:00",
                            video: 37,
                            audio: 200,
                        },
                        total: {
                            calls: '1841(300)',
                            talkTime: "16:00:00",
                            meetingsCalls: 1641,
                            video: 131,
                            audio: 1641,
                            phone: 69,
                        },
                    },
                    {
                        userName: "Spacia (UK)",
                        oneToOne: {
                            calls: '880(55)',
                            talkTime: "10:58:00",
                            video: 537,
                            audio: 1495,
                        },
                        group: {
                            calls: '1120(105)',
                            talkTime: "03:26:00",
                            video: 231,
                            audio: 505,
                        },
                        total: {
                            calls: '2000(360)',
                            talkTime: "14:24:00",
                            meetingsCalls: 1578,
                            video: 222,
                            audio: 1578,
                            phone: 200,
                        },
                    },
                ],
            stackedBarChartData:
            {
                categories: [
                    "Database Team",
                    "Spacia (UK)",
                ],
                series: [
                    {
                        name: "Audio",
                        data: [
                            1641, 1578,
                        ],
                        color: "#4bc0c0",
                        percent:[89,79]
                    },
                    {
                        name: "Video",
                        data: [
                            131, 222,
                        ],
                        color: "#ffcd56",
                        percent:[7,11]
                    },
                    {
                        name: "Phone",
                        data: [
                            69, 200,
                        ],
                        color: "#36a2eb",
                        percent:[4,10]
                    },
                ],
            }
        }

    },

    //38: {
    //    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //    data: {
    //    "categories": [
    //        "ICS Database Team",
    //        "ICS Product Team",
    //        "Tollring (India)",
    //        "Tollring (UK)"
    //    ],
    //    "series": [
    //        {
    //            "name": "Team",
    //            "data": [
    //                9,
    //                33,
    //                174,
    //                91
    //            ],
    //            "color": "#01426D"
    //        },
    //        {
    //            "name": "Group",
    //            "data": [
    //                454,
    //                728,
    //                4892,
    //                1571
    //            ],
    //            "color": "#025C99"
    //        },
    //        {
    //            "name": "PeerToPeer",
    //            "data": [
    //                440,
    //                853,
    //                7476,
    //                2412
    //            ],
    //            "color": "#038EE7"
    //        }
    //    ]
    //}},

    //39: {
    //    description: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.",
    //    data: {
    //    "categories": [
    //        "ICS Database Team",
    //        "Tollring (India)",
    //        "Tollring (UK)"
    //    ],
    //    "series": [
    //        {
    //            "name": "Team",
    //            "data": [
    //                1500,
    //                6390,
    //                2923
    //            ],
    //            "color": "#32007D"
    //        },
    //        {
    //            "name": "Peer",
    //            "data": [
    //                6012,
    //                8991,
    //                2844
    //            ],
    //            "color": "#AA75C1"
    //        }
    //    ]
    //}},

    40: {
        description:
`Use this card to compare call activity profiles across people. Apply the video filter to see the total Teams meetings/calls and talk time on video.

The graph provides a high level visual of calls by person, broken down by call type, to easily see who uses video in teams and the proportion of Teams meetings/calls versus phone calls.

The table shows a full overview of call and meeting activity across your selected people, time period and time of day, broken down by one-to-one and group. Compare total calls, talk time, Teams meetings/calls and phone calls and see the percentage of teams calls that took place using video. Understand how many calls are one-to-one versus those co-attended by more than 2 participants.

Data: Teams calls and meetings, and phone calls.`,
        data:
        {
            groupedGridData: [
                //{
                //    userName: "Abdiel Jimenez",
                //    total: {
                //        calls: "96(20)",
                //        talkTime: "1d, 06:15:22",
                //        audio: 94,
                //        video: 2,
                //        phone:5,
                //    },
                //    oneToOne: {
                //        calls: "81(23)",
                //        talkTime: "14:05:32",
                //        audio: 80,
                //        video: 1,
                //    },
                //    group: {
                //        calls: "15(5)",
                //        talkTime: "15:53:32",
                //        audio: 14,
                //        video: 1,
                //    },

                //},
                {
                    userName: "Derrek Hyndson",
                    total: {
                        calls: "81(65)",
                        talkTime: "1d,01:27:20",
                        meetingandcalls: 11,
                        perVideo: 54,
                        phone:16,

                    },
                    oneToOne: {
                        calls: "35(20)",
                        talkTime: "11:17:44",
                        talkTimeTeamsCall: "11:17:44",
                    },
                    group: {
                        calls: "46(29)",
                        talkTime: "13:37:54",
                        talkTimeTeamsCall: "13:37:54",
                    },

                },
                //{
                //    userName: "Courtney Stanley",
                //    total: {
                //        calls: "62(20)",
                //        talkTime: "1d, 04:49:11",
                //        audio: 59,
                //        video: 3,
                //        phone:2,

                //    },
                //    oneToOne: {
                //        calls: "60(15)",
                //        talkTime: "16:33:43",
                //        audio: 59,
                //        video: 1,
                //    },
                //    group: {
                //        calls: "2(0)",
                //        talkTime: "12:15:28",
                //        audio: 0,
                //        video: 2,
                //    },

                //},
                {
                    userName: "Maren Rotts",
                    total: {
                        calls: "35(24)",
                        talkTime: "11:30:13",
                        meetingandcalls: 12,
                        audio: 1,
                        perVideo: 2,
                        phone:21,

                    },
                    oneToOne: {
                        calls: "16(2)",
                        talkTime: "01:19:31",
                        talkTimeTeamsCall: "01:19:31",
                        audio: 6,
                        video: 0,
                    },
                    group: {
                        calls: "19(1)",
                        talkTime: "09:44:42",
                        talkTimeTeamsCall: "09:44:42",
                        audio: 7,
                        video: 0,
                    },

                },
                //{
                //    userName: "Nikolai Ross",
                //    total: {
                //        calls: "38(0)",
                //        talkTime: "13:34:32",
                //        audio: 38,
                //        video: 0,
                //        phone:7,

                //    },
                //    oneToOne: {
                //        calls: "29(13)",
                //        talkTime: "03:20:49",
                //        audio: 29,
                //        video: 0,
                //    },
                //    group: {
                //        calls: "9(2)",
                //        talkTime: "09:23:53",
                //        audio: 9,
                //        video: 0,
                //    },
                //},
            ],
            //stackedBarChartData:
            //{
            //    categories: [
            //        "Abdiel Jimenez",
            //        "Derick Hayden",
            //        "Courtney Stanley",
            //        "Marc Roberts",
            //        "Nikolai Ross"
            //    ],
            //    series: [
            //        {
            //            name: "Audio",
            //            data: [
            //                15, 28, 20, 25, 5
            //            ],
            //            color: "#4bc0c0",
            //        },
            //        {
            //            name: "Video",
            //            data: [
            //                1, 0, 4, 8, 2
            //            ],
            //            color: "#ffcd56",
            //        },
            //        {
            //            name: "Phone",
            //            data: [
            //                1, 0, 22, 8, 20
            //            ],
            //            color: "#36a2eb",
            //        },
            //    ],
            //},
            stackedBarChartData:
            {
                categories: [
                    "Derrek Hyndson",
                    "Maren Rotts"
                ],
                series: [
                    {
                        name: "Audio",
                        data: [
                            11, 12
                        ],
                        color: "#4bc0c0",
                    },
                    {
                        name: "Video",
                        data: [
                            54, 2
                        ],
                        color: "#ffcd56",
                    },
                    {
                        name: "Phone",
                        data: [
                            16, 21
                        ],
                        color: "#36a2eb",
                    },
                ],
            },
        },
    },
    0: {
        description:
`Use this card to gauge well-being and gain a full understanding of an individual’s collaboration preferences, trends and activity.

View a summary of an individual’s collaboration across meetings, scheduled meetings, calls and messages. Meeting habits show behavioural trends and a list of the top 5 one-to-one collaborators which can be filtered by time in meetings/calls and total messages. Overall message sentiment and network quality are also highlighted visually in this card.

Data: Meetings, scheduled meetings, meeting habits, calls, messages, collaboration network.`,
        data: {
            "initials": "SD", "userName": "Sumit Dhar", "designation": "Java Developer", "email": "sumit.Dhar@abc.com", "image": "", "timePeriod": "Next 30 Days", "callQuality": { "good": 28, "avg": 47, "poor": 24 },
            "calls": { "total": 301, "audio": 100, "video": 180, "externalPhone": 21, "peerCalls": 18, "externalPercent": 32, "totalTalkTime": "4d, 17:34:53", "totalAudioTalkTime": "1d, 17:34:53", "totalVideoTalkTime": "2d, 17:34:53", "totalExternalPhoneTalkTime": "06:47:28", "avgCallQuality": "Average" }, "messages": { "total": 2211, "sent": 2194, "replied": 1, "mentions": 6, "reactions": 388, "avgSentiments": "Neutral", "externalMessages": 22, "group": 21, "peer": 12 },
            "collaborators": {
                "collabList": [{ "userName": "Richie Tozier", "totalOrganized": 58, "totalParticipated": 63, "totalTalkTime": "2d, 01:13:57" }, { "userName": "Janit Sharma", "totalOrganized": 16, "totalParticipated": 29, "totalTalkTime": "16:03:43" }, { "userName": "Manjit Kaur", "totalOrganized": 24, "totalParticipated": 7, "totalTalkTime": "09:23:12" }, { "userName": "Ayushi Sharma", "totalOrganized": 7, "totalParticipated": 21, "totalTalkTime": "07:21:28" }, { "userName": "Derick Hayden", "totalOrganized": 3, "totalParticipated": 15, "totalTalkTime": "03:46:22" }],
                "msgCollabList": [{ "topCollabName": "Stuart Redman", "topCollabCount": 58 }, { "topCollabName": "Richie Tozier", "topCollabCount": 16 }, { "topCollabName": "Nikolai Ross", "topCollabCount": 24 }, { "topCollabName": "Marc Roberts", "topCollabCount": 7 }, { "topCollabName": "Courtney Stanley", "topCollabCount": 3 }],
                "overallCollabList": [{"topCollabName": "Richie Tozier", "topCollabTime" : "2d, 01:30:21"}, {"topCollabName": "Janit Sharma", "topCollabTime" : "1d, 22:06:10"}, {"topCollabName": "Stuart Redman", "topCollabTime" : "1d, 14:40:59"}, {"topCollabName": "Manjit Kaur", "topCollabTime" : "1d, 08:43:36"}, {"topCollabName": "Courtney Stanley", "topCollabTime" : "1d, 05:45:12"}, {"topCollabName": "Derick Hayden", "topCollabTime" : "21:39:16"}]
            },
            "meetings": { "totalTimeInMeeting": "07:56:07", "totalTimeMeetingRecurring": "02:05:00", "totalMeetings": 5, "totalRecurringMeetings": 10, "totalOverlappingMeetings": 70, "totalBackToBackMeetings": 5, "totalMeetingsLate": 10, "totalMeetingsOverran": 30 },
            "nextXTime": { "totalTimeInMeeting": "01:50:09", "totalOverlappingMeetings": 23, "totalTimeMeetingOverlapping":"02:00:00", "totalTimeMeetingRecurring": "10:06:00", "totalRecurringMeetingPer": 40, "totalOverlappingMeetings": 20, "totalTimeMeetingOverlapping": "07:07:00", "totalMeetings": 80 }, "meetingOverview": { "totalMeetings": 46, "totalAttended": 31, "totalNotAttended": 11, "totalStatusUnknown": 4 }
        }
    },
    55: {
        description:      
`Use this card to see levels of collaboration activity within Teams and channels, alongside engagement trends.

View engagement activity within selected Teams, across meetings and messages, highlighting top 5 collaborators. Total activity can be viewed by date to show engagement trends.

Data: Team meetings (internal, external, recurring, talk time), Team messages (posts, replies, mentions, reactions), Message sentiment
`,
        data: {
            "teamMessages": { "totalMessages": 2194, "messagesReactions": 5, "messageMentions": 388,"extPostsRepliesReactions":0, "avgSentiments": "Neutral","goodSentiment":20,"poorSentiment":10,"neutralSentiment":5 },
            "teamMeetings": { "totalMeetings": 21, "internalMeetings": 13, "externalMeetings": 8, "totalRecurringMeetings": 2, "totalTimeInMeeting": "20:08" },    
            "teamCollaborators": {
                "callCollabList": [{ "userName": "Sumit Kumar", "totalMessages": 25, "callDuration": "2d, 01:13" }, { "userName": "Saumya Garg", "totalMessages": 15, "callDuration": "16:03" }, { "userName": "Shweta Singhal", "totalMessages": 9, "callDuration": "09:23" }, { "userName": "Marc Roberts", "totalMessages": 21, "callDuration": "07:21" }, { "userName": "Kartik Rawat", "totalMessages": 3, "callDuration": "03:46" }],
                "messageCollabList": [{ "userName": "Sumit Kumar", "messageCount": 25 }, { "userName": "Anmol Jain", "messageCount": 15 }, { "userName": "Richie Tozier", "messageCount": 9 }, { "userName": "Marc Roberts", "messageCount": 21 }, { "userName": "Nikolai Ross", "messageCount": 3 }]
            },

            teamsStackChartData:

            {
                categories: [
                    "18\\5\\2021",
                    "19\\5\\2021",
                    "20\\5\\2021",
                    "21\\5\\2021",
                    "22\\5\\2021",
                    "23\\5\\2021",
                    "24\\5\\2021",
                ],
                series: [
                    {
                        name: "Collaboration Time (sec.)",
                        data: [
                            1500, 280, 120, 250, 115, 330, 204
                        ],
                        color: "rgb(175, 117, 170)",
                    },

                ],
            },

        }
    },
    57: {
        description: 
`Use this card to analyse an individual’s profile of one-to-one engagement across their reporting network.

View how an individual splits their collaboration time across meetings, calls and messages, with their line manager, direct reports and others in their reporting network.

Data: One-to-one and group meetings/calls and chat, chat sentiment score from Microsoft.`,
        data: {
            name: "Sanjay Kumar",
            designation: "Project Manager",
            email: "Sanjay123@testdata.com",
            userPhoto: "",
            initials: "SK",
            managerOverview: {
                oneToOne: {
                    calls: 3,
                    duration: "01:29:40",
                    perVideoCalls: 0,
                    messages: 28,
                    perPositiveMessages: 18,
                    perNeturalMessages: 72,
                    perNegativeMessages: 10,
                    countPositiveMessages: 5,
                    countNeturalMessages: 20,
                    countNegativeMessages: 3,
                },
                group: {
                    calls: 4,
                    duration: "02:31:55",
                    perVideoCalls: 75,
                    messages: 2,
                    perPositiveMessages: 0,
                    perNeturalMessages: 100,
                    perNegativeMessages: 0,
                    countPositiveMessages: 0,
                    countNeturalMessages: 2,
                    countNegativeMessages: 0,
                },
                collaborationTime: "02:34:25",
                perOverallCollabtime: "12",
                perOverallOneToOneCollabTime: 3,
                perScheduleMeetings: 1,
                countScreenSharing: 3,
            },
            reporteeOverview: {
                oneToOne: {
                    calls: 20,
                    duration: "07:39:23",
                    perVideoCalls: 85,
                    messages: 262,
                    perPositiveMessages: 12,
                    perNeturalMessages: 78,
                    perNegativeMessages: 10,
                    countPositiveMessages: 32,
                    countNeturalMessages: 204,
                    countNegativeMessages: 26,
                },
                group: {
                    calls: 3,
                    duration: "05:38:21",
                    perVideoCalls: 100,
                    messages: 13,
                    perPositiveMessages: 0,
                    perNeturalMessages: 100,
                    perNegativeMessages: 0,
                    countPositiveMessages: 0,
                    countNeturalMessages: 13,
                    countNegativeMessages: 0,
                },
                collaborationTime: "13:38:59",
                perOverallCollabtime: 64,
                perOverallOneToOneCollabTime: 74,
                perScheduleMeetings: 9,
                countScreenSharing: 16,
            },
            othersOverview: {
                oneToOne: {
                    calls: 24,
                    duration: "02:38:11",
                    perVideoCalls: 66.67,
                    messages: 399,
                    perPositiveMessages: 20.3,
                    perNeturalMessages: 69.17,
                    perNegativeMessages: 10.53,
                    countPositiveMessages: 80,
                    countNeturalMessages: 278,
                    countNegativeMessages: 41,
                },
                group: {
                    calls: 4,
                    duration: "01:19:57",
                    perVideoCalls: 75,
                    messages: 9,
                    perPositiveMessages: 11.11,
                    perNeturalMessages: 77.78,
                    perNegativeMessages: 11.11,
                    countPositiveMessages: 1,
                    countNeturalMessages: 7,
                    countNegativeMessages: 1,
                },
                collaborationTime: "04:32:08",
                perOverallCollabtime: 22,
                perOverallOneToOneCollabTime: 26,
                perScheduleMeetings: 3,
                countScreenSharing: 10,
            }
        }
    },
    26: {
        description:
`Use this card to compare the collaboration profiles of two similar Teams. Compare activity, sentiment and active members side-by-side.

Data: Team meetings, Team posts and replies, mentions and reactions, last activity, membership profile, external participants and message sentiment (rated by Microsoft).
`,
        data: {
            initials1: "SM",
            displayName1: "Secure Management",
            replied1: "4(2)",
            sent1: "1(0)",
            mentionedCount1: "6(2)",
            reactionCount1: "0(0)",
            countMeeting1: 0,
            recurrence1: "0(0%)",
            external1: "0(0%)",
            screenSharing1: "0(0%)",
            timeInMeeting1: null,
            sentiment1: "Neutral",
            goodSentiment1: 3,
            poorSentiment1: 1,
            neutralSentiment1: 1,
            outOfHours1: 40,
            teamSummary1: {
                totalChannels: 6,
                totalUsers: 17,
                memberGuests: 12,
                owners: 5,
                activeUsers: 17,
                activeOwnersMembers:"12(5)",
                inactiveOwnersMembers:"0(0)",
                totalMeetings: 0,
                totalMessages: 5,
                latestActivityTime: "01-06-2022 17:49",
                latestActivitySubject: "Post By Devesh Singh",
                nextMeetingTime: null,
                nextMeetingSubject: null,
                nextMeetingOrganiser: null
            },
            initials2: "KM",
            displayName2: "Knowledge Management",
            replied2: "3(0)",
            sent2: "3(0)",
            mentionedCount2: "0(0)",
            reactionCount2: "0(0)",
            countMeeting2: 1,
            recurrence2: "1(100%)",
            external2: "0(0%)",
            screenSharing2: "0(0%)",
            timeInMeeting2: "00:02:02",
            sentiment2: null,
            goodSentiment2: 2,
            poorSentiment2: 2,
            neutralSentiment2: 2,
            outOfHours2: 0,
            teamSummary2: {
                totalChannels: 6,
                totalUsers: 5,
                memberGuests: 1,
                owners: 4,
                activeUsers: 5,
                activeOwnersMembers:"5(1)",
                inactiveOwnersMembers:"0(0)",
                totalMeetings: 1,
                totalMessages: 0,
                latestActivityTime: "01-06-2022 17:59",
                latestActivitySubject: "Call By Parul Ghosh",
                nextMeetingTime: null,
                nextMeetingSubject: null,
                nextMeetingOrganiser: null
            }
        }        
    },
    41: {
        description:
`Use this card to compare network quality ratings across people. Use the filters to refine the data to those people affected by poor network quality. This is especially useful for external-facing roles to monitor customer experience.

Compare network quality ratings across people for a selected date range, rated as a percentage good, average or poor.

Data: Network quality score, as rated by Microsoft.`,
        data: {
            categories: [
                "Abdiel Jimenez",
                "Derick Hayden",
                "Courtney Stanley",
                "Marc Roberts",
                "Nikolai Ross",
                "Ashlyn Houston",
                "Dave Sharma",
                "Charlotte Mcintyre",
                "Sofia Porter",
                "Britney Hooper",
                "Lexie Willis",
                "Fabian Haley",
                "Sunil Nevla",
                "Paul Rovia",
                "Kaley Cannon",
            ],
            series: [
                {
                    "name": "Good",
                    "data": [
                        24, 39, 15, 11, 68, 11, 47, 15, 19, 25, 41, 20, 50, 16, 30
                    ],
                    "color": "#4bc0c0"
                },
                {
                    "name": "Average",
                    "data": [
                        10, 16, 10, 11, 6, 11, 17, 13, 19, 15, 11, 9, 15, 18, 20
                    ],
                    "color": "#ffcd56"
                },
                {
                    "name": "Poor",
                    "data": [
                        4, 9, 5, 2, 6, 2, 4, 8, 15, 5, 4, 1, 5, 1, 3
                    ],
                    "color": "#ff6384"
                }
            ],
        },
    },
    42: {
        description:
`Use this card to help understand overall message sentiment and monitor well-being across selected people.
            
Compare message sentiment, presented as a positive, neutral or negative score, across people within a specified date range.

Data : Message sentiment score based on all chat, posts and replies, as rated by Microsoft.`,
        data: {
            categories: [
                "Abdiel Jimenez",
                "Derick Hayden",
                "Courtney Stanley",
                "Marc Roberts",
                "Nikolai Ross",
                "Ashlyn Houston",
                "Dave Sharma",
                "Charlotte Mcintyre",
                "Sofia Porter",
                "Britney Hooper",
                "Lexie Willis",
                "Fabian Haley",
                "Sunil Nevla",
                "Paul Rovia",
                "Kaley Cannon",
            ],
            series: [
                {
                    "name": "Positive",
                    "data": [
                        24, 39, 15, 11, 68, 11, 47, 15, 19, 25, 41, 20, 50, 16, 30
                    ],
                    "color": "#4bc0c0"
                },
                {
                    "name": "Neutral",
                    "data": [
                        10, 16, 10, 11, 6, 11, 17, 13, 19, 15, 11, 9, 15, 18, 20
                    ],
                    "color": "#ffcd56"
                },
                {
                    "name": "Negative",
                    "data": [
                        4, 9, 5, 2, 6, 2, 4, 8, 15, 5, 4, 1, 5, 1, 3
                    ],
                    "color": "#ff6384"
                }
            ],
        },
    },
    43: {
        description:
`Compare the Platforms used (Android, IOS or Windows) by your people, groups or Teams over a selected period of time.
            
Use this card to understand the collaboration habits of mobile workers.

Data: Collaboration platform`,
        data: {
            categories: [
                "Abdiel Jimenez",
                "Derick Hayden",
                "Courtney Stanley",
                "Marc Roberts",
                "Nikolai Ross",
                "Ashlyn Houston",
                "Dave Sharma",
                "Charlotte Mcintyre",
                "Sofia Porter",
                "Britney Hooper",
                "Lexie Willis",
                "Fabian Haley",
                "Sunil Nevla",
                "Paul Rovia",
                "Kaley Cannon",
                "Alfred Evans",
                "Irvin Norton",
                "Olivia Munn",
                "Dante Cardenas",
                "Areli Singh",
                "Cailyn Ramsey",
                "Jordan Green",
                "Kelly Grayson",
                "Rodrigo Lorento",
                "Rylie Austin",
                "Talan Michael",
                "Salem Mughal",
                "Annie Wilkes",
                "Ashok Sharma",
                "Laurel Parsons",
                "Natalie Cook",
                "Maya Sharma",
            ],
            series: [
                {
                    name: "Android",
                    data: [
                        1, 2, 1, 1, 1, 1, 1, 1, 2, 1, 1, 1, 1, 1, 1, 1, 1, 1, 0, 1, 1, 0, 1,
                        0, 0, 1, 1, 2, 1, 2, 1,
                    ],
                    color: "#4bc0c0",
                },
                {
                    name: "IOS",
                    data: [
                        4, 22, 15, 9, 5, 4, 3, 11, 24, 5, 6, 4, 14, 0, 10, 6, 9, 7, 7, 14,
                        6, 6, 4, 0, 7, 5, 16, 18, 16, 8, 14,
                    ],
                    color: "#ffcd56",
                },
                {
                    name: "Windows",
                    data: [
                        44, 27, 8, 15, 22, 9, 7, 8, 15, 43, 20, 5, 41, 3, 71, 15, 11, 20, 1,
                        16, 1, 7, 1, 3, 15, 37, 11, 36, 17, 12, 9,
                    ],
                    color: "#36a2eb",
                },
                {
                    name: "Web",
                    data: [
                        44, 27, 8, 15, 22, 9, 7, 8, 15, 43, 20, 5, 41, 3, 71, 15, 11, 20, 1,
                        16, 1, 7, 1, 3, 15, 37, 11, 36, 17, 12, 9,
                    ],
                    color: "#ff6384",
                },
                {
                    name: "Unknown",
                    data: [
                        44, 27, 8, 15, 22, 9, 7, 8, 15, 43, 20, 5, 41, 3, 71, 15, 11, 20, 1,
                        16, 1, 7, 1, 3, 15, 37, 11, 36, 17, 12, 9,
                    ],
                    color: "#000000",
                },
            ],
        },
    },
    44: {
        description:
            "List of calls by a user showing status, duration, answer talk times. Use this card to see a complete report of all your calls in a certain period or filter by certain users.",
        data: [
            {
                userName: "Abdiel Jimenez",
                callingNumber: "+441895080831",
                calldirection: "Incoming",
                dateTime: "02/03/2021 09:50:37",
                status: "Answered",
                unreturned: "No",
                totalTalkTime: "01:41:50",
                averagecallQuality: "Good",
            },
            {
                userName: "Derick Hayden",
                callingNumber: "+441895080832",
                calldirection: "Outgoing",
                dateTime: "01/03/2021 12:20:09",
                status: "Answered",
                unreturned: "No",
                totalTalkTime: "01:03:46",
                averagecallQuality: "Average",
            },
            {
                userName: "Courtney Stanley",
                callingNumber: "+441895080833",
                calldirection: "Outgoing",
                dateTime: "02/03/2021 15:33:48",
                status: "Missed",
                unreturned: "No",
                totalTalkTime: "00:00:41",
                averagecallQuality: "Good",
            },
            {
                userName: "Stuart Redman",
                callingNumber: "+441895080834",
                calldirection: "",
                dateTime: "02/03/2021 11:48:07",
                status: "Answered",
                unreturned: "Yes",
                totalTalkTime: "00:03:46",
                averagecallQuality: "Poor",
            },
        ],
    },
    45: {
        description:
`Use this card to compare overall call handling performance across call queues, to maintain customer service levels.

Sort columns to reveal the call queue with the most calls offered, missed, unreturned and any with poor call quality. Check the longest talk time is on the call queues where this is expected and highlight the call queue with the longest wait time.

Set service level expectations and review call queues at a glance to monitor performance.

Data: Call quality score (Microsoft rating), phone calls in call queues, in line with target SLAs.`,
        data: [
            {
                callQueue: "Sales",
                totalCalls: "10",
                answered: "8",
                missed: "2",
                returned: "1",
                returnedInTime: "1",
                unreturned: "1",
                uniqueMissed: "2",
                averageAnswerTime: "00:00:18",
                longestWaitTime: "00:00:45",
                answeredInTarget: "5",
                totalTalkTime: "00:41:46",
                averagecallQuality: "Good",
            },
            {
                callQueue: "Marketing",
                totalCalls: "25",
                answered: "18",
                missed: "7",
                returned: "5",
                returnedInTime: "1",
                unreturned: "2",
                uniqueMissed: "6",
                averageAnswerTime: "00:00:12",
                longestWaitTime: "00:00:30",
                answeredInTarget: "7",
                totalTalkTime: "01:03:51",
                averagecallQuality: "Average",
            },
            {
                callQueue: "Support",
                totalCalls: "45",
                answered: "33",
                missed: "12",
                returned: "7",
                returnedInTime: "4",
                unreturned: "0",
                uniqueMissed: "8",
                averageAnswerTime: "00:00:23",
                longestWaitTime: "00:01:18",
                answeredInTarget: "9",
                totalTalkTime: "01:41:27",
                averagecallQuality: "Poor",
            },
        ],
    },
    47: {
        description:
`View call details for specified call queues including call status, duration, answer and talk times, and average call quality.
            
Use this card to understand or compare call queue performance.

Data: Phone calls`,
        data: [
            {
                callQueue: "Sales",
                callingNumber: "+441895080831",
                dateTime: "02/03/2022 10:48:07",
                agent: "Bob Fleming",
                status: "Answered",
                duration: "00:19:38",
                answerTime: "00:00:20",
                totalTalkTime: "00:03:46",
                averagecallQuality: "Good",
            },
            {
                callQueue: "Marketing",
                callingNumber: "+441895080832",
                dateTime: "02/03/2022 10:50:23",
                agent: "Jed Thomas",
                status: "Answered",
                duration: "00:22:23",
                answerTime: "00:00:14",
                totalTalkTime: "00:15:39",
                averagecallQuality: "Good",
            },
            {
                callQueue: "Support",
                callingNumber: "+441895080833",
                dateTime: "02/03/2022 10:40:51",
                agent: "",
                status: "Missed",
                duration: "00:00:00",
                answerTime: "00:01:00",
                totalTalkTime: "00:00:00",
                averagecallQuality: "Average",
            },
        ],
    },
    48: {
        description:
`Use this card to list calls and sort by any column. Filter by agent(s) or call queue(s) to drill down to specific call activity and to compare performance.

View a list of calling numbers and details for each; agent, call queue, date/time, status, duration, answer and talk time and average network quality.

Data: Phone calls in call queues.`,
        data: [
            {
                agent: "Bob Fleming",
                callQueue: "Sales",
                callingNumber: "+441895080833",
                dateTime: "02/03/2022 10:44:04",
                status: "Answered",
                duration: "00:07:10",
                answerTime: "00:00:12",
                totalTalkTime: "01:03:46",
                averagecallQuality: "Good",
            },
            {
                agent: "Jed Thomas",
                callQueue: "Marketing",
                callingNumber: "+441895080832",
                dateTime: "02/03/2022 14:12:55",
                status: "Answered",
                duration: "00:08:51",
                answerTime: "00:00:20",
                totalTalkTime: "01:32:20",
                averagecallQuality: "Average",
            },
            {
                agent: "Clarissa Wind",
                callQueue: "Support",        
                callingNumber: "+441895080834",
                dateTime: "02/03/2022 10:52:07",
                status: "Answered",
                duration: "00:10:02",
                answerTime: "00:00:10",
                totalTalkTime: "00:39:40",
                averagecallQuality: "Poor",
            },
        ],
    },
    49: {
        description:
`Use this card to identify missed calls within a selected timeframe. This list provides the detail needed to see where calls are being missed, ensure calls are returned and ultimately helps to keep customer experience levels high.

Filter by agent(s) or call queue(s) to view a list of missed calls, the calling number, date/time, call queue and agent.

Data: Missed phone calls.`,
        data: [
            {
                callingNumber: "+441895080800",
                dateTime: "02/03/2022 10:48:07",
                callQueue: "Sales",
                agent: "Bob Fleming",
                organisatFilterType: "callQueue",
            },
            {
                callingNumber: "+441895080815",
                dateTime: "02/03/2022 09:08:42",
                callQueue: "Support",
                agent: "N/A",
                organisatFilterType: "callQueue",
            },
            {
                callingNumber: "+441895080890",
                dateTime: "02/03/2022 10:30:58",
                callQueue: "Marketing",
                agent: "N/A",
                organisatFilterType: "callQueue",
            },
        ],
    },
    50: {
        description:
`Use this card to identify missed calls that have not been returned and sort the list to view those that are outside of target response time or those that can still be returned within your chosen target response time. This list provides the detail needed to maximise customer experience levels.

List missed calls that remain unreturned by the caller or an agent. Filter by agent(s) or call queue(s) to view a list of unreturned missed calls, the calling number, date/time of the last missed call, call queue, agent and if the call is outside of target response time.

Data: Unique unreturned missed phone calls.`,
        data: [
            {
                callingNumber: "+441895080890",
                dateTimeOfLastCall: "02/03/2022 10:00:52",
                countMissedCall: "7",
                callQueueName: "Sales",
                agent: "Bob Fleming",
                outsideTargetTime: "Yes",
                //organisatFilterType: "callQueue",
                organisatinfilterType: "CallQueue", 
            },
            {
                callingNumber: "+441895080890",
                dateTimeOfLastCall: "02/03/2022 09:51:38",
                countMissedCall: "11",
                callQueueName: "Sales",
                agent: "N/A",
                outsideTargetTime: "Yes",
                //organisatFilterType: "callQueue",
                organisatinfilterType: "CallQueue",
            },
            {
                callingNumber: "+441895080890",
                dateTimeOfLastCall: "02/03/2022 09:48:59",
                countMissedCall: "4",
                callQueueName: "Marketing",
                agent: "Jed Thomas",
                outsideTargetTime: "No",
                //organisatFilterType: "callQueue",
                organisatinfilterType: "CallQueue",
            },
        ],
    },
    51: {
        description:
`Use this card to compare agent talk time, answer time and call handling profiles (total offered / answered / missed) across one or multiple call queues.

Filter by call queue(s) to analyse individual agent activity and identify successes or training needs.

Data: Phone calls in call queues.`,
        data: [
            {
                agent: "Bob Fleming",
                offered: "30",
                answered: "24",
                missed: "6",
                talkTime: "03:53:46",
                avgTalkTime: "00:10:15",
                avgAnswerTime: "00:00:13",
            },
            {
                agent: "Jed Thomas",
                offered: "52",
                answered: "38",
                missed: "14",
                talkTime: "04:32:17",
                avgTalkTime: "00:08:36",
                avgAnswerTime: "00:00:18",
            },
            {
                agent: "Clarissa Wind",
                offered: "46",
                answered: "37",
                missed: "9",
                talkTime: "03:59:28",
                avgTalkTime: "00:09:35",
                avgAnswerTime: "00:00:07",
            },
        ],
    },
    52: {
        description:
`Use this card to view hourly call volumes. The graph shows total hourly phone calls answered and missed for selected call queue(s) or agent(s).

This card enables resource levels to be reviewed and managed in busy periods.
            
Data: Phone calls.`,
        data: {
            categories: [
                "8 - 9 am",
                "9 - 10 am",
                "10 - 11 am",
                "11 - 12 pm",
                "12 - 1 pm",
                "1 - 2 pm",
            ],
            series: [
                {
                    name: "Answered",
                    data: [
                        15, 12, 20, 25, 5, 20
                    ],
                    color: "#4bc0c0",
                },
                {
                    name: "Missed",
                    data: [
                        1, 0, 4, 8, 20, 6
                    ],
                    color: "#ff6384",
                },
            ],
        },
    },
    53: {
        description:
`Use this card to understand how long callers will wait before abandoning the call.
            
View the total answered and missed calls by ring time duration.

Data: Phone calls.`,
        data: {
            categories: [
                "0 - 10 Sec",
                "11 - 20 Sec",
                "21 - 30 Sec",
                "31 - 60 Sec",
                "> 60 Sec",
            ],
            series: [
                {
                    name: "Answered",
                    data: [
                        15, 12, 20, 25, 5
                    ],
                    color: "#4bc0c0",
                },
                {
                    name: "Missed",
                    data: [
                        1, 0, 4, 8, 20
                    ],
                    color: "#ff6384",
                },
            ],
        },
    },
    54: {
        description:
`Use this card to profile agent incoming call activity and talk time across call queues.

Filter the card by date/time and agent(s) to display call activity grouped by call queue. For each call queue, view a list of calls offered to the agent(s), how many were answered and missed, as well as the total and average talk time / answer time per queue. Sort columns to profile and use two cards side-by-side with varying time periods or agents to compare and view trends.
            
Data: Phone calls in call queues.`,
        data: [
            {
                callqueue: "Sales",
                offered: "28",
                percentAnsweredStr: "100(28)",
                percentAnswered: "100",
                missed: "0",
                talkTime: "03:54:47",
                avgTalkTime: "00:08:15",
                avgAnswerTime: "00:00:10",
            },
            {
                callqueue: "Marketing",
                offered: "49",
                percentAnsweredStr: "100(49)",
                percentAnswered: "100",
                missed: "0",
                talkTime: "04:28:09",
                avgTalkTime: "00:09:47",
                avgAnswerTime: "00:00:18",
            },
            {
                callqueue: "Support",
                offered: "45",
                percentAnsweredStr: "100(33)",
                percentAnswered: "33",
                missed: "12",
                talkTime: "05:22:09",
                avgTalkTime: "00:08:47",
                avgAnswerTime: "00:00:17",
            },
        ],
    },
    46: {
        description:
`Use this card to maximise customer satisfaction by regularly reviewing service levels in a call queue. Compare time periods (eg. today and yesterday) to review in-depth analysis of phone call activity in a single call queue.

Analyse how calls were answered on a call queue. Review total calls offered, the percentage answered, average answer time, longest wait time and overall grade of service. Use these metrics to understand how easy it is to speak to your organisation. When calls take a long time to be answered, customer satisfaction will decrease.

Return every missed call to keep customers happy. Review missed calls, how many were returned and how. See the percentage of calls returned by a caller (demonstrating proactive management of missed calls) and if they were returned promptly.

Average call quality should be monitored to avoid customer frustration on poor quality calls.

Data: Call quality score (Microsoft rating), phone calls in call queues, in line with target SLAs.`,
        data: {
            "callQueueName": "Sales", "totalCalls": "6256", "answeredCalls": "4921", "missedCalls": "1335", "returnedCalls": "1151",
            "returnedCallsByCaller": "135", "returnedCallsInTime": "849", "returnedCallsOutsideTarget": "167",
            "unreturnedCalls": "184", "uniqueMissedCalls": "957", "totalTalkTime": "07:44:16", "avgAnswerTime": "00:13",
            "longestWaitTime": "04:51", "answeredInTarget": "85", "gos": "78", "pca": "100", "perPoorCallQuality": "15",
            "perAvgCallQuality": "25",
            "perGoodCallQuality": "60", "avgCallQuality": "Good", "perReturnedCallsByCaller" : "12", "perReturnedCallsInTime" : "73", "perReturnedCallsOutsideTarget" : "15"
        }
    },
    56: {
        description:
`Use this card to review Teams within the organization. Identify owners and members, reveal Teams with duplicate or deactivated members and highlight inactive Teams.

View a detailed summary of selected Teams and use the list of insights to refine the data displayed. Download the list of results to action Teams governance within your organization.
            
Data: Team profiles.`,
        data: {
            gridData:[
                {
                    teamName:"Dev Team",
                    lastActivity:"",
                    memExternal: "20(2)",
                    teamOwners:[
                        {
                            name:"Shashank Gupta",
                            image:"",
                            isExternal:false,
                            isDisabled:false
                        }
                    ],
                    members:[
                        {
                            name:"Akshat Singh",
                            image:"",
                            isExternal:false,
                            isDisabled:false
                        },
                        {
                            name:"Amitabh Kumar",
                            image:"",
                            isExternal:false,
                            isDisabled:false
                        },
                        {
                            name:"Das Yadav",
                            image:"",
                            isExternal:false,
                            isDisabled:false
                        },
                        {
                            name:"Ganesh Kumar",
                            image:"",
                            isExternal:false,
                            isDisabled:false
                        },
                        {
                            name:"Saumya Sogani",
                            image:"",
                            isExternal:false,
                            isDisabled:false
                        },
                        {
                            name:"Parul Chopra",
                            image:"",
                            isExternal:false,
                            isDisabled:false
                        }
                    ],
                    activeUsers: [{
                        name:"Lakshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amitabh Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    inactiveUsers: [{
                        name:"Lakshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amitabh Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    deactivatedUsers: [{
                        name:"Akshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amit Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    teamType:"Public",
                    createdDate:"08-04-2020 12:58",
                    channelCount:1,
                    details:[
                        {
                            "ChannelName": "General",
                            "LastActivity":"09-12-2021 15:37",
                            "DateCreated":"09-12-2021 15:37",
                            "ActiveUsersList": [
                                {
                                "name":"Arun Seth",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Anmol Jain",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Kartik  Bhalla",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Prashant Kumar",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Praveen Rana",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                }
                            ],
                        }
                    ],
                },
                
                {
                    "teamName":"Secure Management",
                    "lastActivity":"",
                    memExternal: "10(0)",
                    "teamOwners":[
                        {
                        "name":"Arun Seth",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Anmol Jain",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Kartik  Bhalla",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Prashant Kumar",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Praveen Rana",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        }
                    ],
                    "members":[
                        {
                        "name":"Nikolai Ross",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Ambika Gupta",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Danish Singh",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Devesh Singh",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Lucky Mathur",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Narendra Singh",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Narayana M",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Nishant Kumar",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Pushkar Singh",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Seema  Rani",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Shashank Gupta",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Amit Singh",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        }
                    ],
                    "activeUsers": [{
                        name:"Lakshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amitabh Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    "inactiveUsers": [{
                        name:"Lakshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amitabh Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    "deactivatedUsers": [],
                    "teamType":"Private",
                    "createdDate":"09-12-2021 15:37",
                    "channelCount":6,
                    "details":[
                        {
                            "ChannelName": "SM",
                            "LastActivity":"09-12-2021 15:37",
                            "DateCreated":"09-12-2021 15:37",
                            "ActiveUsersList": [
                                {
                                "name":"Arun Seth",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Anmol Jain",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Kartik  Bhalla",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Prashant Kumar",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Praveen Rana",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                }
                            ],
                        }
                    ],
                },
                {
                    "teamName":"Analytics 365 Launch",
                    "lastActivity":"19-12-2020",
                    memExternal: "20(2)",
                    "teamOwners":[
                        {
                        "name":"Nikolai Ross",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Courtney Stanley",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        }
                    ],
                    "members":[
                        {
                        "name":"Derick Hayden",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Anne Wilson",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Danish Rowley",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Devesh Mann",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Abdiel Jimenez",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"John Ing",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Jamie Clark",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        }
                    ],
                    "activeUsers": [{
                        name:"Lakshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amitabh Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    "inactiveUsers": [{
                        name:"Lakshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amitabh Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    "deactivatedUsers": [{
                        name:"Akshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amit Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    "teamType":"Public",
                    "createdDate":"27-04-2021 17:24",
                    "channelCount":2,
                    "details":[
                        {
                            "ChannelName": "A365",
                            "LastActivity":"09-12-2021 15:37",
                            "DateCreated":"09-12-2021 15:37",
                            "ActiveUsersList": [
                                {
                                "name":"Arun Seth",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Anmol Jain",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Kartik  Bhalla",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Prashant Kumar",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Praveen Rana",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                }
                            ],
                        }
                    ],
                },
                {
                    "teamName":"Knowledge Management",
                    "lastActivity":"16-06-2022 18:01",
                    memExternal: "7(2)",
                    "teamOwners":[
                        {
                        "name":"Praveen Sharma",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Sachin Garg",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Shalini Mehta",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        }
                    ],
                    "members":[
                        {
                        "name":"Akanksha Singh",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        }
                    ],
                    "activeUsers": [{
                        name:"Lakshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amitabh Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    "inactiveUsers": [{
                        name:"Lakshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amitabh Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    "deactivatedUsers": [],
                    "teamType":"Public",
                    "createdDate":"15-04-2020 21:36",
                    "channelCount":6,
                    "details":[
                        {
                            "ChannelName": "KM",
                            "LastActivity":"09-12-2021 15:37",
                            "DateCreated":"09-12-2021 15:37",
                            "ActiveUsersList": [
                                {
                                "name":"Arun Seth",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Anmol Jain",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Kartik  Bhalla",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Prashant Kumar",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Praveen Rana",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                }
                            ],
                        }
                    ],
                },
                {
                    "teamName":"Demo Portal",
                    "lastActivity":"",
                    memExternal: "20(0)",
                    "teamOwners":[
                        {
                        "name":"Arun Seth",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Danish Dhingra",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Himanshu Garg",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        },
                        {
                        "name":"Abdiel Jimenez",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        }
                    ],
                    "members":[
                        
                    ],
                    "activeUsers": [{
                        name:"Lakshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amitabh Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    "inactiveUsers": [{
                        name:"Lakshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amitabh Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    "deactivatedUsers": [{
                        name:"Akshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amit Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    "teamType":"Public",
                    "createdDate":"22-04-2020 16:34",
                    "channelCount":1,
                    "details":[
                        {
                            "ChannelName": "Demo test",
                            "LastActivity":"09-12-2021 15:37",
                            "DateCreated":"09-12-2021 15:37",
                            "ActiveUsersList": [
                                {
                                "name":"Arun Seth",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Anmol Jain",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Kartik  Bhalla",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Prashant Kumar",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Praveen Rana",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                }
                            ],
                        }
                    ],
                },
                {
                    "teamName":"Annex A reviews",
                    memExternal: "12(2)",
                    "lastActivity":"",
                    "teamOwners":[
                        {
                        "name":"Sahil Singh",
                        "image":"",
                        "isExternal":false,
                        "isDisabled":false
                        }
                    ],
                    "members":[
                        
                    ],
                    "activeUsers": [{
                        name:"Lakshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amitabh Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    "inactiveUsers": [{
                        name:"Lakshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amitabh Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    "deactivatedUsers": [{
                        name:"Akshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amit Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    "teamType":"Public",
                    "createdDate":"21-05-2021 15:02",
                    "channelCount":1,
                    "details":[
                        {
                            "ChannelName": "General",
                            "LastActivity":"09-12-2021 15:37",
                            "DateCreated":"09-12-2021 15:37",
                            "ActiveUsersList": [
                                {
                                "name":"Arun Seth",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Anmol Jain",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Kartik  Bhalla",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Prashant Kumar",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Praveen Rana",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                }
                            ],
                        }
                    ],
                },
                {
                    "teamName":"ARM",
                    "lastActivity":"",
                    memExternal: "20(2)",
                    "teamOwners":[
                        
                    ],
                    "members":[
                        
                    ],
                    "activeUsers": [{
                        name:"Lakshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amitabh Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    "inactiveUsers": [{
                        name:"Lakshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amitabh Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    "deactivatedUsers": [{
                        name:"Akshay Singh",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },
                    {
                        name:"Amit Kumar",
                        image:"",
                        isExternal:false,
                        isDisabled:false
                    },],
                    "teamType":"Public",
                    "createdDate":"08-03-2019 16:02",
                    "channelCount":1,
                    "details":[
                        {
                            "ChannelName": "ARM point",
                            "LastActivity":"09-12-2021 15:37",
                            "DateCreated":"09-12-2021 15:37",
                            "ActiveUsersList": [
                                {
                                "name":"Arun Seth",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Anmol Jain",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Kartik  Bhalla",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Prashant Kumar",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                },
                                {
                                "name":"Praveen Rana",
                                "image":"",
                                "isExternal":false,
                                "isDisabled":false
                                }
                            ],
                        }
                    ],
                }
            ]
            
        } 
    },

    58: {
        description: 
`Use this card to analyse a manager’s collaboration profile with their direct reports. Understand the balance of collaboration between one-to-one and co-attended.

The graph plots time spent in one-to-one engagement against percentage of time spent collaborating in a group.

Data: One-to-one time in Teams meetings/calls and chat, plotted against co-attended meetings/calls and messages.`,
        data: {
            maxYaxis: 9,
            managementDetails:[
                {
                   "name":"Maria Santos",
                   "initials":"MS",
                   "image":"",
                   "coAttendancePer":52,
                   "oneToOneDurationPerDay":3
                },
                {
                   "name":"Andreas Fischer",
                   "initials":"AF",
                   "image":"",
                   "coAttendancePer":14,
                   "oneToOneDurationPerDay":0
                },
                {
                   "name":"Aisha Rahman",
                   "initials":"AR",
                   "image":"",
                   "coAttendancePer":75,
                   "oneToOneDurationPerDay":3
                },
                {
                   "name":"Javier Hernandez",
                   "initials":"JH",
                   "image":"",
                   "coAttendancePer":90,
                   "oneToOneDurationPerDay":7
                },
                {
                   "name":"Chloe Leclerc",
                   "initials":"CL",
                   "image":"",
                   "coAttendancePer":7,
                   "oneToOneDurationPerDay":0
                },
                {
                   "name":"Jan Novak",
                   "initials":"JN",
                   "image":"",
                   "coAttendancePer":7,
                   "oneToOneDurationPerDay":0
                },
                {
                   "name":"Fatima Khan",
                   "initials":"FK",
                   "image":"",
                   "coAttendancePer":3,
                   "oneToOneDurationPerDay":5
                },
                {
                   "name":"Viktor Petrovich",
                   "initials":"VP",
                   "image":"",
                   "coAttendancePer":14,
                   "oneToOneDurationPerDay":0
                },
                {
                   "name":"Lars Andersen",
                   "initials":"LA",
                   "image":"",
                   "coAttendancePer":0,
                   "oneToOneDurationPerDay":8
                },
                {
                   "name":"Ananya Rao",
                   "initials":"AR",
                   "image":"",
                   "coAttendancePer":85,
                   "oneToOneDurationPerDay":2
                },
                {
                   "name":"Elena Petrova",
                   "initials":"EP",
                   "image":"",
                   "coAttendancePer":74,
                   "oneToOneDurationPerDay":1
                },
                {
                   "name":"Natasha Patel",
                   "initials":"NP",
                   "image":"",
                   "coAttendancePer":3,
                   "oneToOneDurationPerDay":5
                },
                {
                   "name":"Anton Sokolov",
                   "initials":"AS",
                   "image":"",
                   "coAttendancePer":0,
                   "oneToOneDurationPerDay":0
                },
                {
                   "name":"Luca Rossi",
                   "initials":"LR",
                   "image":"",
                   "coAttendancePer":0,
                   "oneToOneDurationPerDay":0
                },
                {
                   "name":"Amara Singh",
                   "initials":"AS",
                   "image":"",
                   "coAttendancePer":12,
                   "oneToOneDurationPerDay":5
                },
                {
                   "name":"Carmen Smith",
                   "initials":"CS",
                   "image":"",
                   "coAttendancePer":40,
                   "oneToOneDurationPerDay":9
                },
                {
                   "name":"Alexander Wang",
                   "initials":"AW",
                   "image":"",
                   "coAttendancePer":62,
                   "oneToOneDurationPerDay":6
                },
                {
                   "name":"Rajesh Patel",
                   "initials":"RP",
                   "image":"",
                   "coAttendancePer":0,
                   "oneToOneDurationPerDay":1
                },
                {
                   "name":"Hana Suzuki",
                   "initials":"HS",
                   "image":"",
                   "coAttendancePer":10,
                   "oneToOneDurationPerDay":0
                },
                {
                   "name":"Jing Lee",
                   "initials":"JL",
                   "image":"",
                   "coAttendancePer":0,
                   "oneToOneDurationPerDay":0
                },
                {
                   "name":"Joon Kim",
                   "initials":"JK",
                   "image":"",
                   "coAttendancePer":0,
                   "oneToOneDurationPerDay":0
                },
                {
                   "name":"Olivia Andersson",
                   "initials":"OA",
                   "image":"",
                   "coAttendancePer":10,
                   "oneToOneDurationPerDay":0
                },
                {
                   "name":"Bianca Schmidt",
                   "initials":"BS",
                   "image":"",
                   "coAttendancePer":7,
                   "oneToOneDurationPerDay":0
                },
                {
                   "name":"Chen Wu",
                   "initials":"CW",
                   "image":"",
                   "coAttendancePer":14,
                   "oneToOneDurationPerDay":1
                },
                {
                   "name":"Zuri Achebe",
                   "initials":"ZA",
                   "image":"",
                   "coAttendancePer":0,
                   "oneToOneDurationPerDay":0
                }
            ]
        }
    },

    59: {
        description:
`View one-to-one activity broken down by talk time and chat 
            
Use this card to monitor well being and level of engagement.

Data : Meetings, calls and chat messages`,
        data: {
            categories: [
                "04/04/2022",
                "05/04/2022",
                "06/04/2022",
                "07/04/2022",
                "08/04/2022",
            ],
            series: [
                {
                    name: "Collaboration Time",
                    preciseData: [
                        15, 12, 20, 25, 5
                    ],
                    color: "#4bc0c0",
                },
                
            ],
            avgBusinessMessages: 15,
            avgBusinessCallMinutes: 67
        } 
    },
    60: {
        description:
`Use this card to understand how an individual is engaging with their direct reports.

Review individual insights on interactions and a summary of one-to-one and group meetings/calls and messages/sentiment within Teams. The graph displays an overview of collaboration with the individual’s direct reports via meetings/calls and messages.

Data: One-to-one and group Teams meetings/calls and chat, chat sentiment score from Microsoft.`,
        data: {
            userName: "William Thomas",
            designation: "Team Leader",
            email: "william.thomas@analytics-365.com",
            initials: "WT",
            image: "",
            chartData: {
                categories: [ "James", "Alina", "John", "Robert", "Kane", "Alex", "Riem" ],
                series: [
                    {
                        name: "Meetings/Calls",
                        data: [
                            4.7, 7.2, 7.1, 6.3, 8.5, 7.8, 4.3
                        ],
                        color: "#4bc0c0",
                    },
                    {
                        name: "Messages",
                        data: [
                            3.9, 7.9, 7.8, 9.2, 9.8, 8.2, 6.8
                        ],
                        color: "#36a2eb",
                    }
                ]
            },
            groupCollaboration: {
                calls: 50,
                duration: "03:54:37",
                perVideoCalls: 10,
                messages: 155,
                perPositiveMessages: 30,
                perNeturalMessages: 50,
                perNegativeMessages: 20,
                countPositiveMessages:47,
                countNeturalMessages: 77,
                countNegativeMessages: 31,
            },
            oneToOneCollaboration: {
                calls: 36,
                duration: "05:14:00",
                perVideoCalls: 14,
                messages: 283,
                perPositiveMessages: 50,
                perNeturalMessages: 40,
                perNegativeMessages: 10,
                countPositiveMessages: 142,
                countNeturalMessages: 113,
                countNegativeMessages: 28,
            },
            insights: {
                perOverallCollab: 48,
                totalTimeinMeetings: "02:26:59",
                perVideoEnabledCalls: 27,
                userWithLeastOneToOneCollaboartion: "James",
                userWithMostOneToOneCollaboartion: "Kane",
                userWithhighestMessages: "Kane",
                totalScreenSharingCalls: 2, 
            }
        } 
    },
    61:{
        description:`bob fleming hardcoded tenant and userId`,
        data:{
            userName: "Bob Fleming"
        }
    }
};

export default MockData;
