import DashboardApiService from "../../../services/DashboardApiService";
import AuthHelper from "../../../services/auth-helper";

export default class Impersonation {

    static Impersonate(successCallbackFunction)
    {
        var tenantGUID = "tttttttt-tttt-tttt-tttt-countrymatte";
        var tokenName = "tokenName";
        var expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 60);

        var tokenData = {
            tokenName: tokenName.trim(),
            userEmail: "userbob.fleming@countrymatters.co.uk",
            userGUID: "uuuuuuuu-uuuu-uuuu-uuuu-uuuuuuuuubob",
            expiryDate: expiryDate
        }

        AuthHelper.getAccessToken(function (token) {
            
            DashboardApiService.CreateImpersonationToken(tokenData, tenantGUID, token).then((response) => {
                if (response !== undefined && response !== "") {
                    window.localStorage.setItem("impersonationToken", response.token);
                    window.localStorage.setItem("impersonationMode", true);

                    successCallbackFunction();
                }            
            });
        });
    }

    static GetImpersonatedData(successCallbackFunction) {
        var tenantGUID = "tttttttt-tttt-tttt-tttt-countrymatte";
        var tokenName = "tokenName";
        var expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 60);

        var tokenData = {
            tokenName: tokenName.trim(),
            userEmail: "userbob.fleming@countrymatters.co.uk",
            userGUID: "uuuuuuuu-uuuu-uuuu-uuuu-uuuuuuuuubob",
            expiryDate: expiryDate
        }

        var storedToken = window.localStorage.getItem("demoImpersonationToken");

        // if (storedToken != null && storedToken.length > 0) {
        //     successCallbackFunction(storedToken);
        // }
        // else {
        AuthHelper.getAccessToken(function (token) {
            
            DashboardApiService.CreateImpersonationToken(tokenData, tenantGUID, token).then((response) => {
                if (response !== undefined && response !== "") {
                    successCallbackFunction(response.token);

                    window.localStorage.setItem("demoImpersonationToken", response.token);
                }
            });
        });
        // }

    }

    static GetDemoImpersonationTokenForFutureUsage() {
        var tenantGUID = "tttttttt-tttt-tttt-tttt-countrymatte";
        var tokenName = "tokenName";
        var expiryDate = new Date();
        expiryDate.setDate(expiryDate.getDate() + 60);

        var tokenData = {
            tokenName: tokenName.trim(),
            userEmail: "userbob.fleming@countrymatters.co.uk",
            userGUID: "uuuuuuuu-uuuu-uuuu-uuuu-uuuuuuuuubob",
            expiryDate: expiryDate
        }

        var storedToken = window.localStorage.getItem("demoImpersonationToken");

        if (storedToken == null || storedToken.length === 0) {
            AuthHelper.getAccessToken(function (token) {
                
                DashboardApiService.CreateImpersonationToken(tokenData, tenantGUID, token).then((response) => {
                    if (response !== undefined && response !== "") {
                        window.localStorage.setItem("demoImpersonationToken", response.token);
                    }
                });
            });
        }
    }

    static RemoveImpersonation()
    {
        window.localStorage.removeItem("impersonationMode")
        window.localStorage.removeItem("impersonationToken");
    }
}